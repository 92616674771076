import { SyntheticEvent, memo, useEffect, useRef, useState } from "react"
import { Box, Divider, Loader, Typography } from "@tm/components"
import { useUser, WorkTaskInfo } from "@tm/context-distribution"
import { Collapsible } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import {
    AddressSelectionItem,
    channel,
    Item,
    OrderOptionsInternalRequest,
    SelectBackorderRequest,
    SelectBillingModeRequest,
    SelectDeliveryAddressRequest,
    SelectDeliveryDistributionRequest,
    SelectOrderProcessingRequest,
    SelectPaymentRequest,
    SelectShipmentRequest,
    SelectTourRequest,
    SetCustomOrderInformationRequest,
    SetDeliveryDateRequest,
    TourOrderOption,
} from "@tm/models"

import { useChangeOrderOptions } from "../../../data/hooks/useOrderOptions"
import { BillingMode, ShipmentMode, WorkTask } from "../../../data/model"
import { AdditionalInformation, AdditionalInformationRef } from "./components/AdditionalInformation"
import DeliveryAddressesInformation from "./components/DeliveryAddressesInformation"
import BackorderOptions from "./components/BackorderOptions"
import BillingOptions from "./components/BillingOptions"
import DeliveryDateOptions from "./components/DeliveryDateOptions"
import OrderProcessingOptions from "./components/OrderProcessingOptions"
import PaymentOptions from "./components/PaymentOptions"
import ShipmentOptions from "./components/ShipmentOptions"
import Summary from "./components/Summary"
import ErrorAlert from "../ErrorAlert"
import { OrderOptionsGroup } from "../../../models"

type Props = Omit<OrderOptionsInternalRequest, "favoriteOrderOptions" | "workTaskId"> & {
    completeDeliveryTour?: Date
    distributorName?: string
    isInitiallyExpanded?: boolean
    warehouseName?: string
    workTask: WorkTask | WorkTaskInfo
    distributorId?: number
    warehouseId?: string
    orderOptionsGroup?: OrderOptionsGroup
    disabled: boolean
    headerAppendix?: JSX.Element
}

export const WorkTaskOrderOptionsComponent = memo<Props>(
    ({
        completeDeliveryTour,
        warehouseName,
        warehouseId,
        distributorName,
        distributorId,
        workTask,
        headerAppendix,
        isInitiallyExpanded,
        telesalesCustomerNo,
        disabled,
        orderOptionsGroup,
    }) => {
        const { isLoading, isFetching, orderOptions, errorText, hasErrors } = orderOptionsGroup || {}
        const { translateText } = useLocalization()
        const { setUserSetting, userSettings } = useUser() || {}
        const [optionsExpanded, setOptionsExpanded] = useState<boolean>(isInitiallyExpanded ?? false)
        const additionalInformationRef = useRef<AdditionalInformationRef>(null)
        const updateFunctions = useChangeOrderOptions(telesalesCustomerNo)
        const workTaskId = "workTaskId" in workTask ? workTask.workTaskId : workTask.id
        const disableFields = isFetching || disabled
        const [showSave, setShowSave] = useState<boolean>(false)

        useEffect(() => {
            if (userSettings?.orderOptions.expandedByDefault !== undefined) {
                setOptionsExpanded(userSettings.orderOptions.expandedByDefault)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        useEffect(() => {
            const unsubscriptions: Array<() => void> = []

            const worktaskChannel = channel("WORKTASK", workTaskId)
            unsubscriptions.push(
                worktaskChannel.subscribe("BASKET/ARTICLE_ADDED", () => {
                    saveAdditionalInformation()
                }),
                worktaskChannel.subscribe("BASKET/ARTICLE_QUANTITY_CHANGED", () => {
                    saveAdditionalInformation()
                })
                // TODO: Since the additional information is reet after sending the order, it makes no sense to persist them
                // worktaskChannel.subscribe("BASKET/ORDER_SENDING", () => {
                //     saveAdditionalInformation()
                // })
            )

            return () => {
                unsubscriptions.forEach((unsub) => unsub())
            }
        }, [workTaskId])

        function handleSave() {
            setShowSave(true)
            setTimeout(() => {
                setShowSave(false)
            }, 2000)
        }

        // TODO: Refactor the imperative handle stuff away?
        async function updateCustomOrderInformation(customOrderNumber?: string, customOrderComment?: string) {
            if (updateFunctions.setCustomOrderInformationLoading) {
                return
            }

            const request: SetCustomOrderInformationRequest = {
                comment: customOrderComment || "",
                customOrderNumber: customOrderNumber || "",
                workTaskId,
                warehouseId,
                distributorId,
            }
            await updateFunctions.setCustomOrderInformation(request)
            handleSave()
        }

        async function handleChangeAdditionalInformation(customOrderNumber?: string, customOrderComment?: string) {
            if (customOrderComment !== orderOptions?.orderComment || customOrderNumber !== orderOptions?.customerOrderNumber) {
                updateCustomOrderInformation(customOrderNumber, customOrderComment)
            }
        }

        function saveAdditionalInformation() {
            // save additional order information if changed after the user left the page
            if (additionalInformationRef.current) {
                return additionalInformationRef.current.save(handleChangeAdditionalInformation)
            }
        }

        // Important: saveAdditionalInformation may only be executed within the unmount process
        useEffect(() => {
            return () => {
                saveAdditionalInformation()
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        function toggleOptions(e: SyntheticEvent | boolean) {
            if (typeof e !== "boolean") {
                e.stopPropagation && e.stopPropagation()
            }

            if (optionsExpanded) {
                saveAdditionalInformation()
            }
            setOptionsExpanded(!optionsExpanded)
            setUserSetting?.("ORDER_OPTIONS_EXPANDED", !optionsExpanded)
        }

        function renderOrderOptionsgroupDescription() {
            if (!warehouseName && !distributorName) {
                return
            }

            let description = distributorName
            if (description && warehouseName) {
                description += ` - ${warehouseName}`
            } else if (warehouseName) {
                description = warehouseName
            }

            return (
                <Typography order={-1} variant="body1" color="primary" fontWeight="bold" marginRight="1rem">
                    {description}
                </Typography>
            )
        }

        function renderCollapsibleHeader() {
            return (
                <>
                    {renderOrderOptionsgroupDescription()}
                    <Summary
                        optionsExpanded={optionsExpanded}
                        options={orderOptions}
                        showLoader={isFetching}
                        wasSaved={showSave}
                        onToggle={toggleOptions}
                    />
                    {headerAppendix}
                </>
            )
        }

        function renderAdditionalInformation() {
            if (orderOptions?.hasCustomerOrderNumber || orderOptions?.hasOrderComment) {
                return (
                    <AdditionalInformation
                        ref={additionalInformationRef}
                        customerOrderNumberMaxLength={orderOptions.customerOrderNumberMaxLength}
                        hasCustomerOrderNumber={orderOptions.hasCustomerOrderNumber}
                        hasOrderComment={orderOptions.hasOrderComment}
                        orderCommentMaxLength={orderOptions.orderCommentMaxLength}
                        workTask={workTask}
                        warehouseId={orderOptions.warehouseId}
                        distributorId={distributorId}
                    />
                )
            }
        }

        async function handleSetDeliveryAddress(
            selectedAddress: AddressSelectionItem,
            selectedOptionsItem?: Item,
            updateErpInformation?: boolean,
            updateOrderOptions?: boolean,
            updateOrderOnChange?: boolean
        ) {
            const request: SelectDeliveryAddressRequest = {
                selectedAddress,
                workTaskId,
                warehouseId,
                distributorId,
                selectedOptionsItem,
                updateErpInformation,
                updateOrderOptions,
                updateOrderOnChange,
            }
            await updateFunctions.setDeliveryAddress(request)
            handleSave()
        }

        function renderDeliveryAddressInformation() {
            if (orderOptions?.deliveryAddresses?.addresses) {
                return (
                    <DeliveryAddressesInformation
                        options={orderOptions}
                        disableFields={disableFields}
                        onSetDeliveryAddress={handleSetDeliveryAddress}
                    />
                )
            }
        }

        async function handleSetPayment(
            id: string,
            description: string,
            selectedOptionsItem?: Item,
            updateErpInformation?: boolean,
            updateOrderOptions?: boolean
        ) {
            const request: SelectPaymentRequest = {
                optionId: id,
                optionDescription: description,
                workTaskId,
                warehouseId,
                distributorId,
                selectedOptionsItem,
                updateErpInformation,
                updateOrderOptions,
            }
            await updateFunctions.selectPayment(request)
            handleSave()
        }

        function renderPaymentOptions() {
            if (!orderOptions?.paymentOptions) {
                return null
            }

            return <PaymentOptions options={orderOptions} disableFields={disableFields} onSetPayment={handleSetPayment} />
        }

        async function handleSetBilling(
            billingmode: BillingMode,
            selectedOptionsItem?: Item,
            updateErpInformation?: boolean,
            updateOrderOptions?: boolean
        ) {
            const request: SelectBillingModeRequest = {
                optionId: billingmode.id,
                optionDescription: billingmode.description,
                workTaskId,
                warehouseId,
                distributorId,
                selectedOptionsItem,
                updateErpInformation,
                updateOrderOptions,
            }
            await updateFunctions.selectBillingMode(request)
            handleSave()
        }

        function renderBillingOptions() {
            if (!orderOptions?.billingModes) {
                return null
            }

            return <BillingOptions options={orderOptions} disableFields={disableFields} onSetBillingMode={handleSetBilling} />
        }

        async function handleSetShipment(
            shipmentMode: ShipmentMode,
            selectedOptionsItem?: Item,
            updateErpInformation?: boolean,
            updateOrderOptions?: boolean
        ) {
            const request: SelectShipmentRequest = {
                optionId: shipmentMode.id,
                optionDescription: shipmentMode.description,
                workTaskId,
                warehouseId,
                distributorId,
                selectedOptionsItem,
                updateErpInformation,
                updateOrderOptions,
            }
            await updateFunctions.selectShipment(request)
            handleSave()
        }

        async function handleSetDeliveryDistribution(
            id: string,
            description: string,
            selectedOptionsItem?: Item,
            updateErpInformation?: boolean,
            updateOrderOptions?: boolean
        ) {
            const request: SelectDeliveryDistributionRequest = {
                optionId: id,
                optionDescription: description,
                workTaskId,
                warehouseId,
                distributorId,
                selectedOptionsItem,
                updateErpInformation,
                updateOrderOptions,
            }
            await updateFunctions.selectDeliveryDistribution(request)
            handleSave()
        }

        function renderShipmentOptions() {
            if (!orderOptions?.shipmentModes?.shipmentModes?.length && !orderOptions?.deliveryDistribution) {
                return null
            }

            return (
                <ShipmentOptions
                    completeDeliveryTour={completeDeliveryTour}
                    options={orderOptions}
                    disableFields={disableFields}
                    onSetDeliveryDistribution={handleSetDeliveryDistribution}
                    onSetShipment={handleSetShipment}
                />
            )
        }

        async function handleUpdateDeliveryDate(deliveryDate: Date, selectedOptionsItem?: Item) {
            const request: SetDeliveryDateRequest = {
                deliveryDate,
                workTaskId,
                warehouseId,
                distributorId,
                selectedOptionsItem,
            }
            await updateFunctions.setDeliveryDate(request)
            handleSave()
        }

        async function handleSelectTour(
            selectedTour: TourOrderOption,
            selectedOptionsItem?: Item,
            updateErpInformation?: boolean,
            updateOrderOptions?: boolean
        ) {
            const request: SelectTourRequest = {
                tour: selectedTour,
                workTaskId,
                warehouseId,
                distributorId,
                selectedOptionsItem,
                updateErpInformation,
                updateOrderOptions,
            }
            await updateFunctions.selectTour(request)
            handleSave()
        }

        function renderDeliveryDateOptions() {
            if (!orderOptions?.deliveryDate) {
                return null
            }

            return (
                <DeliveryDateOptions
                    options={orderOptions}
                    disableFields={disableFields}
                    onSetTour={handleSelectTour}
                    onUpdateDeliveryDate={handleUpdateDeliveryDate}
                />
            )
        }

        async function handleSetOrderProcessing(
            id: string,
            description: string,
            selectedOptionsItem?: Item,
            updateErpInformation?: boolean,
            updateOrderOptions?: boolean
        ) {
            const request: SelectOrderProcessingRequest = {
                optionId: id,
                optionDescription: description,
                workTaskId,
                warehouseId,
                distributorId,
                selectedOptionsItem,
                updateErpInformation,
                updateOrderOptions,
            }
            await updateFunctions.selectOrderProcessing(request)
            handleSave()
        }

        function renderOrderProcessingOptions() {
            if (!orderOptions?.orderProcessingOptions) {
                return null
            }

            return <OrderProcessingOptions options={orderOptions} disableFields={disableFields} onSetOrderProcessing={handleSetOrderProcessing} />
        }

        async function handleSelectBackorder(
            id: string,
            description: string,
            selectedOptionsItem?: Item,
            updateErpInformation?: boolean,
            updateOrderOptions?: boolean
        ) {
            const request: SelectBackorderRequest = {
                optionId: id,
                optionDescription: description,
                workTaskId,
                warehouseId,
                distributorId,
                selectedOptionsItem,
                updateErpInformation,
                updateOrderOptions,
            }
            await updateFunctions.selectBackorder(request)
            handleSave()
        }

        function renderBackorderOptions() {
            if (!orderOptions?.backorderOptions) {
                return null
            }

            return <BackorderOptions options={orderOptions} disableFields={disableFields} onSelectBackorder={handleSelectBackorder} />
        }

        let content
        if (
            orderOptions?.hasCustomerOrderNumber ||
            orderOptions?.hasOrderComment ||
            orderOptions?.paymentOptions ||
            orderOptions?.shipmentModes ||
            orderOptions?.billingModes ||
            orderOptions?.deliveryAddresses
        ) {
            content = (
                <Collapsible
                    className="tk-basket order-options order-options__collapsible"
                    name={translateText(1250)}
                    initiallyOpened={optionsExpanded}
                    renderHeaderAppendix={renderCollapsibleHeader}
                    onChangeOpen={toggleOptions}
                >
                    <Box margin="0 1rem .5rem 1rem;">
                        <Box display="flex">
                            {renderPaymentOptions()}
                            {renderShipmentOptions()}
                            {renderDeliveryDateOptions()}
                            {renderBillingOptions()}
                            {renderOrderProcessingOptions()}
                            {renderBackorderOptions()}
                            {renderAdditionalInformation()}
                        </Box>
                        {orderOptions?.deliveryAddresses && (
                            <Box marginTop="1.5rem" width="75%">
                                {renderDeliveryAddressInformation()}
                            </Box>
                        )}
                    </Box>
                </Collapsible>
            )
        } else if (!isLoading && (warehouseName || distributorName)) {
            content = (
                <Box display="flex" justifyContent="space-between" flex={1} padding={1} alignItems="center">
                    {renderOrderOptionsgroupDescription()}
                    {headerAppendix}
                </Box>
            )
        }

        return (
            <>
                {hasErrors && !!errorText ? (
                    <ErrorAlert
                        additionalTextId={errorText}
                        prefixText={distributorId ? `${translateText(1760)} ${distributorId.toString()}` : undefined}
                    />
                ) : (
                    <Box padding={1} display="flex" alignItems="center">
                        {isLoading ? <Loader size="small" /> : <Box flex={1}>{content}</Box>}
                    </Box>
                )}
                <Divider />
            </>
        )
    }
)
