import { Button, EasyPopover, EasyPopoverHandles, Icon } from "@tm/components"
import { ReactNode, useRef } from "react"
import { UploadSection } from "../uploadSection/UploadSection"

type Props = {
    children?: ReactNode
}

export function UploadButton({ children }: Props) {
    const popoverRef = useRef<EasyPopoverHandles>(null)

    const renderButton = () => {
        if (children) {
            return children
        }

        return (
            <Button sx={{ height: "32px", width: "32px" }}>
                <Icon name="upload" />
            </Button>
        )
    }

    function closePopup() {
        if (popoverRef.current) {
            popoverRef.current.closePopup()
        }
    }

    return (
        <EasyPopover
            ref={popoverRef}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
        >
            <EasyPopover.ActionButton>{renderButton()}</EasyPopover.ActionButton>
            <EasyPopover.Content>
                <UploadSection handleCloseViewOptions={closePopup} />
            </EasyPopover.Content>
        </EasyPopover>
    )
}
