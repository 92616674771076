import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"

export function sendEmail(emails: string[], mailBody: string, subject?: string) {
    const url = `${getBundleParams().sendEmailUrl}/SendMail`
    const authorization = getStoredAuthorization()
    const body = {
        emails,
        body: mailBody,
        subject,
    }

    return ajax<Array<string>>({ url, body, authorization, method: "POST" })
}
