import { Article, Note } from "@tm/models"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useDeleteNote, useSaveNote } from "@tm/utils"
import { isEqual } from "lodash"
import { useRecoilState } from "recoil"
import { findNotesBuffered } from "../../../data/repositories/articleNotes"
import { getArticleNoteIdentifier } from "../../_shared/article-item/note/helper/mapper"
import { useVehicle } from "./useVehicle"

export type NotesParams = {
    notes: Note[]
    saveNote: (note: Note) => Promise<void>
    saveNoteInProgress: boolean
    deleteNote: (noteId: string) => Promise<void>
    deleteNoteInProgress: boolean
}

export function useNotes(articles: Article[], isVehicleDepending: boolean, isLoadingEnabled: boolean): NotesParams {
    const vehicle = useVehicle()
    const [notesIntern, setNotes] = useState<Note[]>([])
    const [request, setRequest] = useState<string[]>([])
    const [requestedIds, setRequestedIds] = useState<string[]>([])

    const { saveNote: saveNoteIntern, saveNoteInProgress } = useSaveNote()
    const { deleteNote: deleteNoteIntern, deleteNoteInProgress } = useDeleteNote()

    useEffect(
        function createRequest() {
            setRequest((prev) => {
                const ids = articles
                    .flatMap((article) => getArticleNoteIdentifier(article, isVehicleDepending ? vehicle : undefined))
                    .filter((x) => !requestedIds?.includes(x))
                return isEqual(ids, prev) ? prev : ids
            })
        },
        [articles]
    )

    useEffect(
        function rememberIds() {
            if (request.length) {
                setRequestedIds((prev) => [...prev, ...request])
            }
        },
        [request]
    )

    useEffect(
        function loadNotes() {
            if (!request.length || !isLoadingEnabled) {
                return
            }
            findNotesBuffered(request).then((response) => {
                setNotes((prev) => {
                    let newNotes = prev
                    response.forEach((note) => {
                        if (
                            !newNotes.some((x) => x.noteId === note.noteId) ||
                            newNotes.some((x) => x.noteId === note.noteId && x.version !== note.version)
                        ) {
                            newNotes = [...newNotes.filter((x) => x.noteId !== note.noteId), note]
                        }
                    })
                    return newNotes
                })
            })
            setRequest([])
        },
        [request, isLoadingEnabled]
    )

    const saveNote = useCallback(
        async (note: Note) => {
            await saveNoteIntern(note)
            setRequestedIds((prev) => prev.filter((x) => x !== note.noteId))
            setRequest((prev) => [...prev, note.noteId])
        },
        [saveNoteIntern]
    )

    const deleteNote = useCallback(
        async (noteId: string) => {
            await deleteNoteIntern(noteId)
            setNotes((prev) => prev.filter((x) => x.noteId !== noteId))
        },
        [deleteNoteIntern]
    )

    const notes = useMemo(() => notesIntern.filter((x) => requestedIds?.includes(x.noteId)) ?? [], [notesIntern, requestedIds])

    return useMemo(
        () => ({
            notes,
            saveNote,
            saveNoteInProgress,
            deleteNote,
            deleteNoteInProgress,
        }),
        [notes, saveNote, saveNoteInProgress, deleteNote, deleteNoteInProgress]
    )
}
