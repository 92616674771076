import { Box, BoxProps, Icon, LinearProgress, Stack, Typography, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useCallback, useMemo } from "react"
import { useLocation } from "react-router"
import { PureListProps, WholesalerArticle } from "@tm/models"
import { usePureList } from "./usePureList"
import { PureArticleItem } from "./PureArticleItem"
import { ArticleListConfigurationProvider } from "../ListV2/ArticleListConfiguration"
import { getArticleGroups } from "../ListV2/helpers"
import { ArticleGroupHeader } from "../list/components/ArticleGroupHeader/ArticleGroupHeader"
import SensitiveGraphics from "../list/components/sensitive-graphic"
import { ArticleSelection } from "../ListV2/components/ArticleSelection"
import { ArticleListActionsProvider } from "../ListV2/ArticleListActions"
import { ArticleGroupParams, ArticleListActions } from "../ListV2/models"
import { useArticleSelection } from "../ListV2/hooks/useArticleSelection"
import { PartsListImageCoordinate } from "../../data/model"
import { WholesalerArticleItem } from "./WholesalerArticleItem"

const AbsoluteArticleSelection = styled(ArticleSelection)({
    position: "absolute",
    transform: "transLateY(-50%)",
    margin: 0,
})

export function PureListComponent(props: PureListProps) {
    return (
        <ArticleListConfigurationProvider>
            <PureList {...props} />
        </ArticleListConfigurationProvider>
    )
}

type WholesalerArticleGroup = {
    type: "WholesalerArticle"
    articles: WholesalerArticle[]
}

function PureList({ listId, listType, disableArticleGrouping }: PureListProps) {
    const { data, isLoading, isInitialized, ...restProps } = usePureList(listId, listType)
    const { pathname } = useLocation()

    const { translateText } = useLocalization()

    const groupedArticles = useMemo(() => {
        if (disableArticleGrouping) {
            return null
        }

        const groups: (ArticleGroupParams | WholesalerArticleGroup)[] = []
        if (data?.articles) {
            groups.push(...getArticleGroups(data.articles, false))
        }
        if (data?.type === "alternative" && data.wholesalerParts?.length) {
            groups.unshift({ type: "WholesalerArticle", articles: data.wholesalerParts })
        }
        return groups
    }, [data?.articles, disableArticleGrouping])

    const { toggleSelectedArticle, selectedArticles } = useArticleSelection()

    const handleSelectImageCoordinate = useCallback(
        (coordinate: PartsListImageCoordinate) => {
            if (!data?.articles) {
                return
            }
            const articles = data.articles.filter(
                (part) => part.internalId === coordinate.referencedArticle && part.imageCoordinates?.includes(`,${coordinate.id};`)
            )
            articles.forEach(toggleSelectedArticle)
        },
        [data?.articles]
    )

    const articleListActions = useMemo<ArticleListActions>(() => {
        return {}
    }, [])

    if (!isInitialized) {
        return null
    }

    if (isLoading) {
        return <LinearProgress />
    }

    if (!data) {
        return <h1>{translateText(198)}</h1>
    }

    // TODO: calc() is extremely dirty here. as soon as we build a new details modal, we can build this another way
    // Added also the width.. its not perfect but it works for now, also there we need a better soulution.
    const listProps: BoxProps = pathname.includes("/modal") ? { height: "calc(100vh - 150px)", overflow: "auto", width: "calc(100vw - 350px)" } : {}

    const renderArticles = () => {
        if (disableArticleGrouping) {
            return (
                <Stack padding={0.5}>
                    {data?.articles?.map((article) => (
                        <PureArticleItem key={article.internalId} article={article} {...restProps} />
                    ))}
                </Stack>
            )
        }

        return (
            <>
                {groupedArticles?.map((group) => {
                    if ((group as ArticleGroupParams).productGroup) {
                        const { productGroup, articles } = group as ArticleGroupParams
                        return (
                            <Stack key={productGroup.id}>
                                <ArticleGroupHeader title={productGroup.name} productGroupId={productGroup.id} groupArticlesCount={articles.length} />
                                <Stack padding={0.5}>
                                    {articles.map((article) => (
                                        <PureArticleItem key={article.internalId} article={article} {...restProps} />
                                    ))}
                                </Stack>
                            </Stack>
                        )
                    }
                    const { articles } = group as WholesalerArticleGroup
                    return (
                        <Stack padding={0.5} key="WholesalerArticles">
                            {articles.map((wholesalerArticle) => (
                                <WholesalerArticleItem
                                    key={wholesalerArticle.itemId}
                                    article={wholesalerArticle}
                                    options={restProps.options}
                                    basketQuantities={restProps.wholesalerBasketQuantities}
                                    updateBasketQuantities={restProps.updateWholesalerBasketQuantities}
                                />
                            ))}
                        </Stack>
                    )
                })}
            </>
        )
    }

    return (
        <ArticleListActionsProvider value={articleListActions}>
            <Stack flex={1} position="relative">
                {data.type === "alternative" && (
                    <Stack direction="row" gap={0.5} padding={1}>
                        <Icon name="info" size="14px" />
                        <Typography variant="label">{translateText(12893)}</Typography>
                    </Stack>
                )}
                {data.type === "partslist" && data.partsListImage && (
                    <Box p={0.5}>
                        <SensitiveGraphics
                            image={data.partsListImage}
                            imageCoordinates={data.imageCoordinates}
                            selectedArticles={selectedArticles}
                            onArticleSelect={handleSelectImageCoordinate}
                        />
                    </Box>
                )}
                <>
                    <AbsoluteArticleSelection maxArticleNumberToCompareReached erpInfosData={restProps.erpInfosData} />
                    <Box {...listProps}>{renderArticles()}</Box>
                </>
            </Stack>
        </ArticleListActionsProvider>
    )
}
