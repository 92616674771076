import { DefaultAvailability, Box, Tooltip as MuiTooltip, Popper, styled, tooltipClasses, Icon, Tooltip } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { Availability as AvailabilityControl, AvailabilityIconWithDescription, AvailabilityIconWithTour } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Availability, AvailabilityComponentProps, AvailabilityStatus, ErpIconDisplayType } from "@tm/models"
import { mapAvailabilityStatusToIconName, registerOutsideClick } from "@tm/utils"
import { useEffect, useMemo, useRef, useState } from "react"
import { style } from "typestyle"
import { Coparts, Hess, Matthies, Pv, Stg } from "../trader"
import { getBundleParams } from "../utils"
import ImageWithBadge from "./image-with-badge"
import WarehouseAvailabilityImagesWithBadge from "./warehouse-availability-images-with-badge"
import WarehouseTableTooltip from "./warehouse-table-tooltip"

/** Attention: Only filled and used, if getBundleParams().hideAvailabilityWithoutErpExternalSystem is set to true */
let hasExternalErpSystems: boolean | undefined

const popoverTypes = ["warehouses", "coparts"]
const divWrapperClassName = style({
    display: "inline-block",
    lineHeight: 0,
})

const FullWidthTooltip = styled(MuiTooltip)(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "900px",
        padding: "0 8px",
    },
}))

export default function AvailabilityWrapperComponent(props: AvailabilityComponentProps) {
    const {
        tour,
        error,
        indicatorPosition,
        warehouses,
        requestedQuantity,
        partnerSystemSpecialIcons,
        selectedShipmentMode,
        specialProcurementInformation,
        hasReplacedArticles,
        useSpecialProcurement,
        warehouseTableCurrentItem,
        className,
        showSmallIcons,
        erpSystem,
        itemOrderableInformation,
    } = props
    const { translateText, date } = useLocalization()
    const user = useUser()
    const [popoverActive, setPopoverActive] = useState(false)
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
    const wrapperRef = useRef<HTMLDivElement>(null)
    const mappingScheme = getBundleParams().availabilityIconMappingScheme

    const availability = useMemo((): Availability | undefined => {
        // overwrites the description to give the client a recommendation for action
        if (hasReplacedArticles && props.availability && props.availability.type === AvailabilityStatus.NoLongerAvailable) {
            return {
                ...props.availability,
                shortDescription: translateText(12999),
            }
        }

        return props.availability
    }, [props.availability, hasReplacedArticles, translateText])

    useEffect(() => {
        if (popoverTypes.indexOf(getBundleParams().availabilityTooltipClick) >= 0 && wrapperRef.current && popoverActive) {
            return registerOutsideClick(wrapperRef.current, () => {
                setPopoverActive(false)
                setAnchorEl(null)
            })
        }
    }, [setPopoverActive, popoverActive])

    if (
        availability?.type === AvailabilityStatus.NotYetRequested ||
        availability?.type === AvailabilityStatus.NotYetDefined ||
        availability?.type === AvailabilityStatus.NotShown
    ) {
        return null
    }

    if (getBundleParams().hideAvailabilityWithoutErpExternalSystem && user?.userContext?.externalModules) {
        if (hasExternalErpSystems === undefined) {
            hasExternalErpSystems = user.userContext.externalModules.some((x) => x.isInfoEnabled)
        }
        if (!hasExternalErpSystems) {
            return null
        }
    }

    let onClick
    let popover
    switch (getBundleParams().availabilityTooltipClick) {
        case "warehouses":
            onClick = () => {
                setPopoverActive(!popoverActive)
                setAnchorEl(wrapperRef.current)
            }
            if (warehouses && warehouses.length) {
                popover = (
                    <Popper anchorEl={anchorEl} open={popoverActive} sx={{ zIndex: 1 }}>
                        <Box p={1} bgcolor="Background" boxShadow="3" borderRadius={1}>
                            <WarehouseTableTooltip
                                hasReplacedArticles={hasReplacedArticles}
                                availability={availability && (availability.description || availability.shortDescription)}
                                warehouses={warehouses}
                                requestedQuantity={requestedQuantity || 1}
                                partnerSystemSpecialIcons={partnerSystemSpecialIcons}
                                title={erpSystem?.description}
                            />
                        </Box>
                    </Popper>
                )
            }
            break
        default:
            onClick = () => {
                if (props.onClick) {
                    props.onClick(useSpecialProcurement)
                }
            }
            break
    }

    let tourDate
    let tourName
    if (tour) {
        const { tourPrefix } = getBundleParams()
        if (typeof tour === "string") {
            tourName = tourPrefix ? `${translateText(tourPrefix)} ${tour}` : tour
        } else if (tour.id) {
            tourName = tourPrefix ? `${translateText(tourPrefix)} ${tour.id}` : tour.id

            if (tour.tourStart) {
                tourDate = date(tour.tourStart, "ddd")
            } else if (tour.weekday && tour.weekday.length) {
                tourDate = tour.weekday[0]
            }
        }
    }

    let iconName = mapAvailabilityStatusToIconName(AvailabilityStatus.Unknown, mappingScheme)
    let imageUrl

    if (availability) {
        iconName = mapAvailabilityStatusToIconName(availability.type, mappingScheme)
        if (availability.icon?.useIconFromUrl && availability.icon.url) {
            const smallIcon = availability.additionalIcons?.find((icon) => icon?.displayType === ErpIconDisplayType.Small)
            imageUrl = showSmallIcons && smallIcon ? smallIcon.url : availability.icon.url
        }
    }

    let description
    if (availability) {
        description = availability.shortDescription || ""
    } else if (error) {
        description = translateText(12684)
    } else {
        description = translateText(171)
    }

    let control
    switch (getBundleParams().availabilityControlLayout) {
        case "image-with-badge":
            let quantities = 0
            warehouses?.forEach((w) =>
                w.quantities?.forEach((q) => {
                    quantities += q.value || 0
                })
            )

            control = <ImageWithBadge hideBadge={!warehouses} quantities={quantities} imageUrl={imageUrl} iconName={iconName} />
            break
        case "hess":
            control = <Hess.AvailabilityComponent availability={availability} tourName={tourName} warehouses={warehouses} onClick={onClick} />
            break
        case "coparts":
            control = (
                <Coparts.AvailabilityComponent
                    availability={availability}
                    tour={tour}
                    warehouses={warehouses}
                    specialProcurementInformation={specialProcurementInformation}
                    warehouseTableCurrentItem={warehouseTableCurrentItem}
                    onClick={onClick}
                />
            )
            break
        case "icon-with-tour":
            control = (
                <AvailabilityIconWithTour
                    status={availability?.type || AvailabilityStatus.Unknown}
                    iconname={iconName}
                    imageUrl={imageUrl}
                    tourDate={tourDate}
                    tourName={tourName}
                    onClick={onClick}
                />
            )
            break
        case "icon-with-description":
            control = (
                <AvailabilityIconWithDescription
                    status={availability?.type || AvailabilityStatus.Unknown}
                    description={description}
                    iconname={iconName}
                    imageUrl={imageUrl}
                    onClick={onClick}
                />
            )
            break
        case "bm":
            control = (
                <DefaultAvailability
                    mapping="bm"
                    status={availability?.type || AvailabilityStatus.Unknown}
                    description={description}
                    iconName={iconName}
                    imageUrl={imageUrl}
                    onClick={onClick}
                />
            )
            break
        case "matthies":
            control = <Matthies.AvailabilityComponent availability={availability} tour={tour} />
            break
        case "default":
        default:
            control = (
                <AvailabilityControl
                    status={availability?.type || AvailabilityStatus.Unknown}
                    description={description}
                    iconname={iconName}
                    imageUrl={imageUrl}
                    tourDate={tourDate}
                    tourName={tourName}
                    indicatorPosition={indicatorPosition}
                    onClick={onClick}
                />
            )
            break
    }

    const { availabilityTooltipHover } = getBundleParams()

    switch (availabilityTooltipHover) {
        case "warehouses":
            control = (
                <FullWidthTooltip
                    variant="light"
                    color="primary"
                    title={
                        <WarehouseTableTooltip
                            availability={availability && (availability.description || availability.shortDescription)}
                            warehouses={warehouses}
                            requestedQuantity={requestedQuantity || 1}
                            partnerSystemSpecialIcons={partnerSystemSpecialIcons}
                            title={erpSystem?.description}
                        />
                    }
                >
                    <div className={divWrapperClassName}>{control}</div>
                </FullWidthTooltip>
            )
            break
        case "warehouse-availability-images-with-badge":
            if (warehouses?.length) {
                control = (
                    <MuiTooltip
                        variant="light"
                        color="primary"
                        title={<WarehouseAvailabilityImagesWithBadge warehouses={warehouses} title={erpSystem?.description} />}
                    >
                        <div className={divWrapperClassName}>{control}</div>
                    </MuiTooltip>
                )
            }
            break
        case "stg":
            control = (
                <Tooltip
                    title={
                        availability && (
                            <Stg.AvailabilityTooltip
                                availability={availability}
                                warehouses={warehouses}
                                warehouseTableCurrentItem={warehouseTableCurrentItem}
                                selectedShipmentMode={selectedShipmentMode}
                            />
                        )
                    }
                    placement="top"
                >
                    <div className={divWrapperClassName}>{control}</div>
                </Tooltip>
            )
            break
        case "pv":
            control = (
                <Tooltip
                    title={
                        availability && (
                            <Pv.AvailabilityTooltip
                                availability={availability}
                                warehouses={warehouses}
                                warehouseTableCurrentItem={warehouseTableCurrentItem}
                                selectedShipmentMode={selectedShipmentMode}
                            />
                        )
                    }
                    placement="top"
                >
                    <div className={divWrapperClassName}>{control}</div>
                </Tooltip>
            )
            break
        case "none":
            break
        case "default":
        default:
            control = (
                <Tooltip title={availability && (availability?.shortDescription || availability?.description || "")} placement="top">
                    <div className={divWrapperClassName}>{control}</div>
                </Tooltip>
            )
            break
    }

    return (
        <div className={`erp-info__availability__wrapper ${className || ""}`} style={{ display: "flex" }} ref={wrapperRef}>
            {control}
            {popover}
            {itemOrderableInformation && (
                <MuiTooltip title={itemOrderableInformation}>
                    <div>
                        <Icon name="attention-circle" sx={{ marginLeft: "4px", alignSelf: "flex-start" }} />
                    </div>
                </MuiTooltip>
            )}
        </div>
    )
}
