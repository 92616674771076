import { useWorkTask } from "@tm/context-distribution"
import { ModuleGroupId, ModuleId, ModuleMode, channel } from "@tm/models"
import { FC, useEffect } from "react"
import { getCorrectModuleGroupId } from "./helpers"
import DemoBanner from "./components/DemoBanner"

type Props = {
    activationRoute?: string
    moduleGroupId: ModuleGroupId | "inspectionData"
    moduleId: ModuleId
    moduleName: string
    moduleMode: ModuleMode
}

const DemoAlert: FC<Props> = ({ moduleGroupId, moduleId, moduleName, moduleMode, activationRoute }) => {
    const { workTaskId, workTask } = useWorkTask() || {}
    const { vehicle } = workTask || {}

    const vehicleType = vehicle?.vehicleType

    useEffect(() => {
        if (moduleId && workTaskId && vehicleType && ["dat", "eurotax", "tecrmi", "autodata", "haynespro"].includes(moduleId)) {
            channel("GLOBAL").publish("MVC/RESIZE", { moduleMode: moduleMode as string })
        }
    }, [workTaskId, vehicleType])

    if (!workTaskId || !vehicleType || !moduleGroupId) {
        return null
    }

    const moduleGroupIdAux = getCorrectModuleGroupId(moduleGroupId, vehicleType)

    if (!moduleId || !moduleName || !moduleGroupIdAux || !["TD", "SD", "RT", "NONE"].includes(moduleMode?.toString())) {
        return null
    }

    if (moduleGroupId === "inspectionData" && moduleId === "haynespro") {
        return null
    }

    return (
        <DemoBanner
            moduleGroupId={moduleGroupId}
            moduleGroupIdAux={moduleGroupIdAux}
            moduleId={moduleId}
            vehicleType={vehicleType}
            moduleName={moduleName}
            activationRoute={activationRoute}
        />
    )
}

export default DemoAlert
