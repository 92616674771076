import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Icon, SharePopover, styled, Switch, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { channel, IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { getComponentStyles } from "../../_shared/styles"
import { PrintOptions } from "../../../data/models"
import { useFastServiceStore } from "../../../data"
import { printMaintenance } from "../business"
import { useIdStorageByWorkTaskLoadable } from "../../../../../notifications/src/data/hooks"
import { usePdfDownload } from "../../../helpers/hooks/usePdfDownload"
import SendEmailField from "../../_shared/sendEmailField"
import { sendEmail } from "../../../data/repositories/sendEmail"

type Props = {
    printState: PrintOptions
    onPrintStateChange: (printOptions: keyof PrintOptions) => void
    isEmailSelected: boolean
    onEmailSelect: (isSelected: boolean) => void
    onSendToCartelligence: (chatId: string, pdfUrl: string) => void
}

export default function SharePrint({ printState, onPrintStateChange, isEmailSelected, onEmailSelect, onSendToCartelligence }: Props) {
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()
    const classNames = getComponentStyles()
    const { userSettings, userContext } = useUser()
    const customer = useWorkTask()?.workTask?.customer
    const pdfUrlError = useFastServiceStore((state) => state.printError)
    const pdfUrlLoading = useFastServiceStore((state) => state.printLoading)
    const pdfUrl = useFastServiceStore((state) => state.pdfUrl)
    const { chatId } = useIdStorageByWorkTaskLoadable() ?? {}
    const { downloadPdf } = usePdfDownload()

    const handlePrint = () => {
        printState &&
            printMaintenance(translateText, printState, userContext.account?.username ?? "", userSettings, customer, () => {
                const printBtn = document.getElementById("print-btn")
                printBtn?.click()
            })
    }

    const handleCheck = (key: keyof PrintOptions) => {
        onPrintStateChange(key)
    }

    const handleEmailSelection = (isSelected: boolean) => {
        onEmailSelect(isSelected)
    }

    const handleSendToCartelligence = () => {
        if (!chatId || !pdfUrl) {
            return
        }

        onSendToCartelligence(chatId, pdfUrl)
    }

    const handleSendEmail = (emails: string[]) => {
        if (!pdfUrl || !emails) {
            return
        }

        try {
            sendEmail(emails, pdfUrl, translateText(13782))
        } catch (error) {
            channel("APP").publish("TOAST_MESSAGE/SHOW", {
                message: translateText(13808),
                icon: "error",
                skin: "warning",
                closeDelay: 3000,
            })
        }

        channel("APP").publish("TOAST_MESSAGE/SHOW", {
            message: translateText(13807),
            icon: "check",
            skin: "success",
            closeDelay: 3000,
        })
    }

    return (
        <SharePopover
            title={translateText(13384)}
            link={pdfUrl}
            onRequestLink={handlePrint}
            isRequestingLink={pdfUrlLoading}
            errorMessage={pdfUrlError ? translateText(13301) : undefined}
            buttonProps={{ className: classNames.buttonPadding, size: "extralarge", variant: "contained" }}
        >
            <SharePopover.ShareButtonWrapper>
                <SharePopover.WhatsAppButton
                    disabled={!pdfUrl}
                    message={`${translateText(13782)}: ${pdfUrl}`}
                    onClick={() => handleEmailSelection(false)}
                />
                <SharePopover.EmailButton
                    recipient={customer?.email}
                    subject={translateText(13782)}
                    body={pdfUrl}
                    onClick={() => handleEmailSelection(true)}
                />
                {renderMicro("notifications", "send-cost-estimation-button", {
                    asShareButton: true,
                    disabled: !pdfUrl,
                    onClick: () => handleSendToCartelligence(),
                })}
                <SharePopover.DownloadButton disabled={!pdfUrl} onClick={() => (pdfUrl ? downloadPdf(pdfUrl) : {})} />
            </SharePopover.ShareButtonWrapper>
            <SharePopover.AdditionalPopoverContent>
                <Accordion defaultExpanded>
                    <StyledAccordionSummary expandIcon={<Icon name="down" />}>
                        <StyledBox>
                            <StyledSubtitle>{translateText(844)}</StyledSubtitle>
                        </StyledBox>
                    </StyledAccordionSummary>
                    <AccordionDetails>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={6}>
                                <Switch
                                    label={<StyledText>{translateText(12964)}</StyledText>}
                                    labelPlacement="start"
                                    checked={printState.inspectionTimes}
                                    onChange={() => handleCheck("inspectionTimes")}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Switch
                                    label={<StyledText>{translateText(12965)}</StyledText>}
                                    labelPlacement="start"
                                    checked={printState.remarks}
                                    onChange={() => handleCheck("remarks")}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Switch
                                    label={<StyledText>{translateText(12685)}</StyledText>}
                                    labelPlacement="start"
                                    checked={printState.images}
                                    onChange={() => handleCheck("images")}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Switch
                                    label={<StyledText>{translateText(240)}</StyledText>}
                                    labelPlacement="start"
                                    checked={printState.tyres}
                                    onChange={() => handleCheck("tyres")}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Switch
                                    label={<StyledText>{translateText(12966)}</StyledText>}
                                    labelPlacement="start"
                                    checked={printState.nextMaintenance}
                                    onChange={() => handleCheck("nextMaintenance")}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Switch
                                    label={<StyledText>{translateText(12845)}</StyledText>}
                                    labelPlacement="start"
                                    checked={printState.showDefects}
                                    onChange={() => handleCheck("showDefects")}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                {isEmailSelected && <SendEmailField onSendEmail={handleSendEmail} />}
            </SharePopover.AdditionalPopoverContent>
        </SharePopover>
    )
}

const StyledBox = styled(Box)({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
})

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    "& .MuiAccordionSummary-content.Mui-expanded": {
        marginLeft: theme.spacing(1),
    },
}))

const StyledSubtitle = styled(Typography)({
    variant: "body1",
    fontWeight: "medium",
})

const StyledText = styled(Typography)({
    variant: "body2",
    fontWeight: "regular",
})
