import { useUser } from "@tm/context-distribution"
import { useParams } from "react-router"
import { BasketBundleParams, ECounterType, PriceType } from "@tm/models"
import { Icon, LinkButton, Popover, useTheme } from "@tm/components"
import { TmaHelper, renderRoute } from "@tm/utils"
import Morpheus from "@tm/morpheus"
import { useLocalization } from "@tm/localization"
import { ReactNode, useState } from "react"
import tinycolor from "tinycolor2"
import { OrderCalculation } from "../../../../data/model"
import PartsTotalsTable from "./PartsTotalsTable"
import { LoaderSmall, StyledBox } from "../../../StyledComponents"
import { getBundleParams } from "../../../../utils"
import { SummaryRouteProps } from "../../component"
import ErrorButton from "../../../_shared/ErrorButton"
import { useBasketModuleParameter } from "../../../../hooks/useBasketModuleParameter"

type Props = {
    workTaskId: string
    calculatedOrder?: OrderCalculation
    partsBasketRoute: string
    hasErrors: boolean
    isLoading: boolean
    hideButtonText?: boolean
    showPopover?: boolean
    additionalErrorTextId?: number
}

export function PartsTotals(props: Props) {
    const { isLoading, showPopover, hasErrors, hideButtonText, partsBasketRoute, calculatedOrder, additionalErrorTextId, workTaskId } = props
    const { userSettings } = useUser() ?? {}
    const { translateText, currency } = useLocalization()
    const matchParams = useParams<SummaryRouteProps>()
    const theme = useTheme()
    const { hideBasket } = getBundleParams()
    const { hasWorkTaskOrder } = useBasketModuleParameter()
    const [partsTotalsPopoverEl, setPartsTotalsPopoverEl] = useState<HTMLDivElement>()

    if (!hasWorkTaskOrder) {
        return null
    }

    const componentBackgroundColor = theme.overwrites?.components?.basketSummary?.component?.backgroundColor
    const linkButtonVariant =
        theme.overwrites?.components?.basketSummary?.linkButton?.variant || tinycolor(componentBackgroundColor).isDark() ? "textLight" : "text"
    const contrastColor = linkButtonVariant === "textLight" ? theme.palette.common.white : theme.palette.common.black
    const linkButtonFontWeight = theme.overwrites?.components?.basketSummary?.linkButton?.fontWeight
    const linkButtonFontFamily = theme.overwrites?.components?.basketSummary?.linkButton?.fontFamily
    const linkButtonSize = theme.overwrites?.components?.basketSummary?.linkButton?.size

    const showPartsRetailPrice =
        !userSettings?.showPurchasePrice ||
        !(userSettings?.showPurchasePriceInSummary ?? !Morpheus.getParams<BasketBundleParams>("basket").showRetailPriceInSummaryByDefault) // 1 of 2: If the usersetting is not set fall back to the config param because that is the behaviour it was before this change (NEXT-20908). MLE: Added this because it caused NEXT-22836

    function renderTotalsPopover() {
        const priceType = showPartsRetailPrice ? PriceType.Retail : PriceType.Purchase
        if (calculatedOrder) {
            return (
                <StyledBox>
                    <PartsTotalsTable calculatedOrder={calculatedOrder} priceType={priceType} />
                </StyledBox>
            )
        }
    }

    function getBasketSummary() {
        // ErpTotalsInOrder needs to be used here. This might change if in the future UiConfig changes will make it customizable.
        if (!calculatedOrder?.erpTotalsInOrder) {
            return null
        }
        const calculatedPrices = Object.values(calculatedOrder?.erpTotalsInOrder)
        const purchasePrice = calculatedPrices.find((price) => price?.type === PriceType.Purchase)
        const retailPrice =
            calculatedPrices.find((price) => price?.type === PriceType.Retail) ??
            calculatedPrices.find((price) => price?.type === PriceType.RecommendedRetail)

        if (showPartsRetailPrice || purchasePrice === undefined) {
            return retailPrice
        }

        return purchasePrice
    }

    function getPartsBasketUrl(): string {
        return renderRoute(partsBasketRoute, { ...matchParams })
    }

    function BasketButton() {
        if (hideBasket) {
            return null
        }
        const totalPartsPrice = getBasketSummary()

        const title = hideButtonText ? "" : ` (*${translateText(802)})`
        const buttonText = `${currency(totalPartsPrice?.value ?? 0, totalPartsPrice?.currencyCode_Iso_4217 ?? "")}*`
        const buttonTitle = hasErrors ? "" : translateText(90) + title
        let buttonContent: ReactNode = buttonText

        if (hasErrors) {
            buttonContent = (
                <>
                    {buttonText} <ErrorButton basketWithErrors workTaskIds={[workTaskId]} additionalErrorTextId={additionalErrorTextId} />
                </>
            )
        } else if (isLoading) {
            buttonContent = <LoaderSmall />
        }

        let linkButtonTextColor = ""
        if (showPartsRetailPrice || totalPartsPrice === undefined) {
            linkButtonTextColor = ""
        } else if (linkButtonVariant === "text" && componentBackgroundColor !== theme.palette.highlight.main) {
            linkButtonTextColor = theme.palette.highlight.main
        }
        linkButtonTextColor += " !important"

        return (
            <LinkButton
                sx={{
                    borderRadius: 0,
                    border: 0,
                    fontWeight: showPartsRetailPrice || totalPartsPrice === undefined ? linkButtonFontWeight : 600,
                    fontFamily: linkButtonFontFamily,
                    color: linkButtonTextColor,
                }}
                variant={linkButtonVariant}
                startIcon={<Icon name="cart-summary" color={contrastColor} />}
                title={showPopover ? "" : buttonTitle}
                to={getPartsBasketUrl()}
                onClick={() => {
                    TmaHelper.GeneralCountEvent.Call(ECounterType.TopBarBasket)
                }}
                size={linkButtonSize || "extralarge"}
            >
                {buttonContent}
            </LinkButton>
        )
    }

    return (
        <>
            {showPopover ? (
                <div
                    onMouseEnter={(event) => {
                        setPartsTotalsPopoverEl(event.currentTarget)
                    }}
                    onMouseLeave={() => {
                        setPartsTotalsPopoverEl(undefined)
                    }}
                >
                    <BasketButton />
                    {!!renderTotalsPopover() && (
                        <Popover
                            open={!!partsTotalsPopoverEl}
                            anchorEl={partsTotalsPopoverEl}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            sx={{ pointerEvents: "none" }}
                        >
                            {renderTotalsPopover()}
                        </Popover>
                    )}
                </div>
            ) : (
                <BasketButton />
            )}
        </>
    )
}
