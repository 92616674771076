import { Box, Button, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ArticleListSortingMode } from "@tm/models"
import { useCallback, useMemo } from "react"
import { ArticleSelection } from "../components/ArticleSelection"
import { ArticleListWrapper } from "../components/ArticleListWrapper"
import { useProductGroupsModuleState } from "./ProductGroupsModuleState"
import { usePartsViewOptions } from "../hooks/usePartsViewOptions"
import { NoSearchResult } from "../components/NoSearchResult"
import { ArticleGroup } from "../components/ArticleGroup"
import { NextPageLoader } from "../components/NextPageLoader"

const ModalArticleListWrapper = styled(ArticleListWrapper)({ height: "100%", paddingLeft: 0, paddingRight: 1, margin: 1, overflow: "hidden" })

export default function ProductGroupsList() {
    const { translateText } = useLocalization()
    const noResult = useProductGroupsModuleState((x) => x.params.noResult)
    const erpInfosData = useProductGroupsModuleState((x) => x.erpInfosData)
    const attributes = useProductGroupsModuleState((x) => x.params.attributes)
    const toggleAttribute = useProductGroupsModuleState((x) => x.params.toggleAttribute)
    const setAttributes = useProductGroupsModuleState((x) => x.params.setAttributes)

    const options = useProductGroupsModuleState((x) => x.options)
    const sortingProductGroups = useProductGroupsModuleState((x) => x.params.sorting.productGroups)
    const activateSortingForProductGroups = useProductGroupsModuleState((x) => x.params.sorting.setForProductGroups)
    const { articleGroups, isLoading, isLoaded, isFailed, hasNextPage, loadNextPage, isStalling, isFrontendFiltered } = useProductGroupsModuleState(
        (x) => x.articles
    )
    const productGroupTopicIds = useProductGroupsModuleState((x) => x.productGroupTopicIds)

    const {
        partsViewSettings: { showVehicleRecordsFilters },
    } = usePartsViewOptions()

    const usedAttributeFilters = useMemo(() => attributes.map((x) => x.query ?? `${x.id}|${x.key ?? ""}`), [attributes])

    const handleResetAttributeFilters = useCallback(() => {
        if (usedAttributeFilters.length) {
            setAttributes([])
        }
    }, [usedAttributeFilters, setAttributes])

    const handleChangeSorting = useCallback(
        (productGroupId: number, value: ArticleListSortingMode | undefined) => {
            activateSortingForProductGroups([productGroupId], value)
        },
        [activateSortingForProductGroups]
    )

    const showPageLoader = ((hasNextPage || isLoading) && !isFrontendFiltered) || isStalling

    return (
        <ModalArticleListWrapper>
            <ArticleSelection
                maxArticleNumberToCompareReached
                showArticleComparision
                erpInfosData={erpInfosData}
                hideBasketButton={options.hideAddToBasketButton}
            />

            {(isLoaded || !isLoading) && (noResult || (isFailed && !showPageLoader) || !articleGroups.length) && (
                <NoSearchResult searchType="direct" />
            )}

            {isLoaded &&
                !isStalling &&
                articleGroups.map((group) => (
                    <ArticleGroup
                        key={group.productGroup.id}
                        group={group}
                        currentSorting={sortingProductGroups[group.productGroup.id]}
                        onChangeSorting={handleChangeSorting}
                        showFilterOptions={showVehicleRecordsFilters}
                        usedAttributeFilters={usedAttributeFilters}
                        onToggleAttributeFilter={toggleAttribute}
                        onResetAttributeFilters={handleResetAttributeFilters}
                        productGroupTopicIds={productGroupTopicIds}
                    />
                ))}

            {showPageLoader && (
                <NextPageLoader
                    loadNextPage={loadNextPage}
                    canLoadNextArticles={hasNextPage}
                    skeletonArticleCount={articleGroups.length ? 1 : 5}
                    skeletonCategory
                />
            )}

            {hasNextPage && isFrontendFiltered && (
                <Box display="flex" justifyContent="center">
                    <Button color="highlight" onClick={loadNextPage}>
                        {translateText(12430)}
                    </Button>
                </Box>
            )}
        </ModalArticleListWrapper>
    )
}
