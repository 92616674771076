import { em, important, percent, rem } from "csx"
import { ReactElement, useCallback, useMemo } from "react"
import { NumberSelect, Tooltip } from "@tm/components"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Icon, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { RouteComponentProps, withRouter } from "@tm/utils"
import { findIconByAppTypeId } from "../../data/helpers"
import { RimDetailsArticle } from "../../data/model"

type Props = RouteComponentProps & {
    item: RimDetailsArticle
    vehicleId: string | undefined
    rimPart: Article
    isSelected: boolean
    onArticleAttributeSelect(article: RimDetailsArticle): void
    onQuantyChnage: (item: RimDetailsArticle, value: number) => void
    repairTimesRoute: string
}

function DetailsArticleItem({ item, vehicleId, rimPart, onQuantyChnage, isSelected, onArticleAttributeSelect }: Props): ReactElement {
    const { renderMicro } = useMicro<IMicros>()
    const { translateText } = useLocalization()
    const style = useMemo(() => getStyle(), [])

    const handleAttributeSelect = useCallback(() => {
        onArticleAttributeSelect(item)
    }, [])

    const handleQuantityChange = useCallback((amountItem: number) => {
        onQuantyChnage(item, amountItem)
    }, [])


    const renderActions = useCallback(() => {
        return (
            <div className="tk-basket">
                <div className="add-to-basket">
                    <NumberSelect
                        maxItemsToShow={5}
                        minAmount={1}
                        value={item.quantity}
                        onValueChange={handleQuantityChange}
                    />
                </div>
            </div>
        )
    }, [])

    const renderDescription = useCallback(() => {
        return (
            <div className={style.customIcons}>
                <Text className={style.centerBore} size="l" modifiers="strong">
                    {item.centerBore}
                </Text>
                <Text className={style.descriptionLabel}>{item.label}</Text>
                {renderAppTypeIcon()}
            </div>
        )
    }, [])

    const renderAppTypeIcon = useCallback(() => {
        return (
            <div className={style.customIcons}>
                {item.appType &&
                    <Tooltip title={translateText(item.appType)}>
                        <Icon name={findIconByAppTypeId(item.appTypeID)} skin="primary" size="l" className={style.customIcons} />
                    </Tooltip>
                }
            </div>
        )
    }, [])

    // TODO: This looks like some workaround and should be changed/removed
    const renderCustomErpInformation = useCallback(() => {
        return (
            <>
                {renderMicro!("erp", "erp-info-prices", { data: rimPart })}
                {renderMicro!("erp", "erp-info-availability", { data: rimPart })}
            </>
        )
    }, [rimPart.quantity])

    // TODO: This looks like some workaround and should be changed/removed
    const renderMicroPart = useCallback(() => {
        return (
            <>
                {renderMicro("parts", "part-item", {
                    vehicleId,
                    hiddenCells: {
                        supplierName: true,
                        prefix: true,
                        attributes: true,
                        references: true,
                        information: true,
                        documents: true,
                        additionalButtons: true,
                        additionalActions: true,
                        erpMemo: true,
                        messages: true,
                    },
                    showArticleImage: true,
                    canFilterArticleAttributes: false,
                    renderCustomErpInformation,
                    className: style.articleItem,
                    part: rimPart,
                    showActions: true,
                    isSelected,
                    onArticleSelect: handleAttributeSelect,
                    renderBuyActions: renderActions,
                    renderAdditionalDescription: renderDescription,
                })}
            </>

        )
    }, [rimPart.internalId, rimPart.quantity, isSelected])

    return renderMicroPart()
}

export default withRouter(DetailsArticleItem)

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        customIcons: {
            marginLeft: theme.margin.m,
            display: "flex",
            flex: 1,
            alignItems: "center",
        },
        centerBore: {
            marginRight: theme.margin.s,
            width: em(3),
            minWidth: em(3)
        },
        descriptionLabel: {
            display: "flex",
            flex: "0 0 50%"
        },
        articleItem: {
            flexWrap: "nowrap",
            flex: 1,
            marginRight: em(.5),
            $nest: {
                ".article-list__item__buy": {//only for wm
                    flexBasis: percent(30),
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    $nest: {
                        ".price": {
                            flexWrap: important("nowrap"),
                            width: "max-content"
                        },
                        ".price .text--s": {
                            fontSize: rem(1) //hackish but it was required for wm
                        }
                    }
                },
                ".article-list__item": {//only for wm
                    $nest: {
                        "&__buy > div:last-child": {
                            marginTop: important(em(1)),
                            marginBottom: important(em(.25)),
                            flexDirection: important("row"),
                            $nest: {
                                ".additional-actions": {
                                    marginTop: important("unset")
                                }
                            }
                        },
                        "&__buy > div:first-child": {
                            width: "100%",
                            minWidth: "24em"
                        }
                    }
                },
                ".article__cell": {
                    marginRight: theme.margin.m,
                    $nest: {
                        "&--thumbnail": {
                            flexBasis: percent(7),
                            $nest: {
                                ".partslist-hint": {
                                    display: "none"
                                }
                            }
                        },
                        "&--supplier": {
                            flexBasis: percent(9),
                            $nest: {
                                ".supplier__name": {
                                    width: important("unset")
                                }
                            }
                        },
                        "&--numbers": {
                            flexBasis: percent(14),
                            $nest: {
                                ".article__numbers": {
                                    width: "unset",
                                }
                            }
                        },
                        "&--description": {
                            flexBasis: percent(57),
                            justifyContent: "flex-start",
                            display: "flex",
                            $nest: {
                                ".article__description": {
                                    flex: 1
                                }
                            }
                        },
                        "&--erp-information": {
                            flexBasis: em(18),
                            $nest: {
                                ".tk-erp": {
                                    flexBasis: percent(50)
                                }
                            }
                        },
                        "&--attributes": {
                            marginLeft: 0
                        },
                        "&--actions": {
                            $nest: {
                                ".tk-erp": {
                                    width: em(6),
                                },
                                ".checkbox": {
                                    width: em(1.6)
                                }

                            }
                        }
                    }
                }
            }
        }
    })(DetailsArticleItem)
}
