import { Box, Button, Icon, styled, TextField, Typography } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { useEffect, useMemo, useRef, useState } from "react"
import { isEmailValid } from "../../helpers/isEmailValid"

type Props = {
    onSendEmail: (email: string[]) => void
}

export default function SendEmailField({ onSendEmail }: Props) {
    const { translateText } = useLocalization()
    const customer = useWorkTask()?.workTask?.customer
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [email, setEmail] = useState(customer?.email ?? "")
    const [disableEmailInput, setDisableEmailInput] = useState(true)
    const emails = email.split(";").map((mail) => mail.trim())
    const checkEmails = useMemo(() => emails.every((mail) => isEmailValid(mail)), [emails])

    useEffect(() => {
        if (!disableEmailInput && inputRef.current) {
            inputRef.current.focus()
        }
    }, [disableEmailInput])

    const handleEmailChange = (value: string) => {
        setEmail(value)
    }

    const handleEmailInput = (value: boolean) => {
        if (!value && inputRef.current) {
            inputRef.current.focus()
        }
        setDisableEmailInput(value)
    }

    const handleAddEmail = () => {
        if (checkEmails) {
            setEmail((prev) => `${prev ? `${prev}; ` : ""}`)
            setDisableEmailInput(false)
        }
    }

    const handleSendEmail = () => {
        onSendEmail(emails)
    }

    return (
        <SendEmailContainer>
            <StyledSubtitle>{translateText(13777)}</StyledSubtitle>
            <Box sx={{ display: "flex" }}>
                <EmailOptionButton
                    size="medium"
                    startIcon={<Icon name="add" />}
                    onClick={handleAddEmail}
                    sx={{ marginLeft: "none", marginRight: "0.2em" }}
                />
                <TextField
                    placeholder={customer?.email ?? translateText(109)}
                    value={email}
                    onChange={(event) => handleEmailChange(event.target.value)}
                    inputRef={inputRef}
                    disabled={disableEmailInput}
                    sx={{ width: "30%" }}
                />
                <EmailOptionButton size="medium" startIcon={<Icon name="edit_change" />} onClick={() => handleEmailInput(false)} />
                <EmailOptionButton
                    size="medium"
                    color="success"
                    startIcon={<Icon name="checked-filled" />}
                    onClick={() => handleEmailInput(true)}
                    disabled={disableEmailInput}
                />
            </Box>
            <SendButtonContainer>
                <SendButton
                    className="submit"
                    size="large"
                    color="success"
                    startIcon={<Icon name="paperplane" />}
                    onClick={handleSendEmail}
                    disabled={!checkEmails}
                >
                    {translateText(642)}
                </SendButton>
            </SendButtonContainer>
        </SendEmailContainer>
    )
}

const StyledSubtitle = styled(Typography)({
    variant: "body1",
    fontWeight: "medium",
})

const SendEmailContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
})

const SendButtonContainer = styled(Box)({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
})

const SendButton = styled(Button)({
    width: "10%",
})

const EmailOptionButton = styled(Button)({
    marginTop: "1em",
    marginLeft: "0.2em",
})
