import Morpheus, { AsyncAction } from "@tm/morpheus"
import { batch } from "react-redux"
import { ComponentActionType } from "."
import { getBundleParams } from '../../../utils'
import { AvailabilityFilterType, BundleActions, BundleActionTypes } from "../../../business"
import { Repositories } from "../../../data"
import { FilterCriterias, WheelSelectionSteps } from "../../../data/enums"
import { AdditionalVehicleInfo, RimErpInfo, RimItem } from "../../../data/model"
import { _2DRimSize } from "../../../data/repositories/wheels-load2DRimSizes/model"
import { DriveRightTiresRespone } from "../../../data/repositories/wheels-loadDriveRightTyres/model"
import { MainState } from "../../main"
import { createErpInformationRequestItem, createRimArticlesRequest, createRimFiltersRequest, createRimSizesRequest, mapSelectedFiltersForRequest } from "./helper"
import { createNextRimArticlesRequest } from "./helpers"
import { RimFilters, SelectedFilters, WheelsBaseState } from "./model"
import { ErpContainer, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"

export type BaseComponentActionType = BundleActionTypes
    | { type: 'RIM_FILTERS_LOADING' }
    | { type: 'RIM_FILTERS_LOADED', payload: { rimFilters: RimFilters, selectedFilters?: SelectedFilters } }

    | { type: 'RIM_ARTICLES_LOADING' }
    | { type: 'RIM_ARTICLES_LOADED', payload: { rims: Array<RimItem>, hasMoreItems: boolean } }
    | { type: "RIM_ARTICLES_ERROR" }

    | { type: "RIM_NEXT_ARTICLES_LOADED", payload: RimItem[] }
    | { type: 'RIM_NEXT_ARTICLES_LOADING' }
    | { type: 'RIM_NEXT_ARTICLES_ERROR' }

    | { type: 'UPDATE_SELECTED_FILTERS', payload: SelectedFilters }

    | { type: 'DRIVE_RIGHTS_TYRES_LOADING' }
    | { type: 'DRIVE_RIGHTS_TYRES_LOADED', payload: DriveRightTiresRespone }
    | { type: 'DRIVE_RIGHTS_TYRES_ERROR' }

    | { type: "ADDITIONAL_VEH_INFOS_LOADING" }
    | { type: "ADDITIONAL_VEH_INFOS_LOADED", payload: AdditionalVehicleInfo[] }
    | { type: "ADDITIONAL_VEH_INFOS_ERROR" }

    | { type: "SELECTED_RIM_ARTICLE_UPDATE", payload: { rimItem: RimItem, size: string } }
    | { type: "SELECTED_RIM_ARTICLE_LOADING" }
    | { type: "SELECTED_RIM_ARTICLE_LOADED", payload: { sizes: _2DRimSize[], selectedSize: string, defaultSizes?: _2DRimSize[], defaultRimSize?: string, } }
    | { type: "SELECTED_RIM_ARTICLE_ERROR" }

    | { type: "CHANGE_RIM_INFO_AND_SETTINGS_VISIBILITY", payload: { rimInfoVisibility: boolean, rimSettingsVIsibility: boolean } }

    | { type: "APPLY_BUTTON_LOADING", payload: boolean }

    | { type: "SAVE_SELECTED_CAR_PARK", payload: number }
    | { type: "RIM_ERP_INFORMATION_LOADING", payload: RimErpInfo[] }
    | { type: "RIM_ERP_INFORMATION_ERROR" }
    | { type: "SAVE_SELECTED_RIM_SIZE", payload: RimErpInfo }
    | { type: "CHANGE_AVAILABILITY_FILTER", payload: AvailabilityFilterType }


export const BASE_DEFAULT_STATE: WheelsBaseState = {
    carPark: {
        attributes: [],
        carparkId: 0,
    },
    filters: {
        colors: [],
        designs: [],
        ece: [],
        inches: [],
        manufacturers: [],
        noModification: [],
        offsets: [],
        snowChainsCompatible: [],
        widths: [],
        winterprofed: [],
        type: [],
        loading: false,
        error: false
    },
    articles: {
        data: [],
        pageIndex: 1,
        error: false,
        loading: false
    },
    selectedFilters: {
        '-100': "",
        '74': "",
        '73': "",
        '2217': "",
        '8976': "",
        '8977': "",
        "9184": "",
        "-103": "",
        "-102": "",
        "-104": "",
        "-106": "",
        "-111": "",
        "-112": "",
    },
    isAdditionalManufacturerSelected: false,
    availabilityFilter: AvailabilityFilterType.None,
    driveRightTyres: {
        frontTires: [],
        rearTires: [],
    },
    sensorListResult: { data: [] },
    vehicleInfosResult: { data: [] },

    showRimInfo: false,
    showRimSettings: false,
    selectedRimItem: undefined,
    initFromHistory: false,
    initialized: false,
    selectedCarParkId: 0,
    selectedRimSize: undefined
}

export function reduce(state = { ...BASE_DEFAULT_STATE }, action: ComponentActionType): WheelsBaseState {
    switch (action.type) {
        case "RIM_FILTERS_LOADING": {
            return {
                ...state,
                filters: {
                    ...BASE_DEFAULT_STATE.filters,
                    loading: true,
                    error: false
                }
            }
        }
        case "RIM_FILTERS_LOADED": {
            return {
                ...state,
                filters: {
                    ...action.payload.rimFilters,
                    loading: false,
                    error: false,
                },
                selectedFilters: {
                    ...state.selectedFilters,
                    ...action.payload.selectedFilters
                }
            }
        }
        case "RIM_ARTICLES_LOADING": {
            return {
                ...state,
                articles: {
                    data: [],
                    error: false,
                    loading: true,
                    pageIndex: 1,
                }
            }
        }
        case "RIM_ARTICLES_LOADED": {
            const { selectedRimItem } = state
            const { rims, hasMoreItems } = action.payload
            const prevSelectedRim = rims.find(x => selectedRimItem && (x.idRimDesign == selectedRimItem.idRimDesign && x.sizeInfo?.some(y => y.size == state.selectedSize)))
            const shouldSelectFirstRim = !prevSelectedRim
            return {
                ...state,
                articles: {
                    ...state.articles,
                    data: rims,
                    loading: false,
                    error: false,
                    hasMoreItems,
                    pageIndex: 1
                },
                ...shouldSelectFirstRim && rims?.[0] &&
                { selectedRimItem: rims[0], selectedSize: rims[0].sizeInfo?.last()?.size },
                ...prevSelectedRim && {
                    selectedRimItem: prevSelectedRim
                },
                displayStateReset: false,
                initFromHistory: false,
                initialized: true
            }
        }
        case "RIM_ARTICLES_ERROR": {
            return {
                ...state,
                articles: {
                    pageIndex: 1,
                    hasMoreItems: false,
                    data: [],
                    loading: false,
                    error: true
                },
                filters: {
                    ...state.filters,
                    error: true
                },
                initialized: true
            }
        }
        case "RIM_NEXT_ARTICLES_LOADED": {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    data: [...state.articles.data, ...action.payload],
                    loading: false,
                    hasMoreItems: !!action.payload.length,
                    loadingNextItems: false,
                    pageIndex: state.articles.pageIndex + 1
                },
                initFromHistory: false
            }
        }
        case 'RIM_NEXT_ARTICLES_LOADING': {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    loadingNextItems: true,
                },
            }
        }
        case 'RIM_NEXT_ARTICLES_ERROR': {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    loadingNextItems: false,
                    hasMoreItems: false
                },
            }
        }
        case "SEND_SENSOR_ITEMS_RESPONSE": {
            return {
                ...state,
                sensorListResult: {
                    data: action.payload
                }
            }
        }
        case "ADDITIONAL_VEH_INFOS_LOADING": {
            return {
                ...state,
                vehicleInfosResult: {
                    data: [],
                    error: false,
                    loading: true,
                }
            }
        }
        case "ADDITIONAL_VEH_INFOS_LOADED": {
            return {
                ...state,
                vehicleInfosResult: {
                    data: action.payload,
                    error: false,
                    loading: false,
                }
            }
        }
        case "ADDITIONAL_VEH_INFOS_ERROR": {
            return {
                ...state,
                vehicleInfosResult: {
                    data: [],
                    error: true,
                    loading: false,
                }
            }
        }
        case "DRIVE_RIGHTS_TYRES_LOADING": {
            return {
                ...state,
                driveRightTyres: {
                    error: false,
                    loading: true,
                    frontTires: [],
                    rearTires: []
                }
            }
        }
        case "DRIVE_RIGHTS_TYRES_LOADED": {
            const { frontTires, rearTires } = action.payload
            return {
                ...state,
                driveRightTyres: {
                    error: false,
                    loading: false,
                    frontTires,
                    rearTires
                }
            }
        }
        case "DRIVE_RIGHTS_TYRES_ERROR": {
            return {
                ...state,
                driveRightTyres: {
                    error: true,
                    loading: false,
                    frontTires: [],
                    rearTires: []
                }
            }
        }
        case "SELECTED_RIM_ARTICLE_UPDATE": {
            const { rimItem, size } = action.payload
            return {
                ...state,
                selectedRimItem: rimItem,
                selectedSize: size
            }
        }
        case "SELECTED_RIM_ARTICLE_LOADING": {
            return {
                ...state,
                selectedRimError: false,
                selectedRimLoading: true
            }
        }
        case "SELECTED_RIM_ARTICLE_LOADED": {
            return {
                ...state,
                selectedRimError: false,
                selectedRimLoading: false,
            }
        }
        case "SELECTED_RIM_ARTICLE_ERROR": {
            return {
                ...state,
                selectedRimError: true,
                selectedRimLoading: false
            }
        }
        case "CHANGE_RIM_INFO_AND_SETTINGS_VISIBILITY": {
            return {
                ...state,
                showRimInfo: action.payload.rimInfoVisibility,
                showRimSettings: action.payload.rimSettingsVIsibility
            }
        }
        case "SWITCH_CONFIGURATOR_DISPLAY": {
            return {
                ...state,
                showRimInfo: false,
                showRimSettings: false
            }
        }
        //TODO: this is done in vehicle-selection, perhaps we don't need here
        case "SEND_SELECTED_CARD_PARK": {
            return {
                ...state,
                initialized: false,
                carPark: action.payload,
                selectedRimItem: BASE_DEFAULT_STATE.selectedRimItem,
                articles: BASE_DEFAULT_STATE.articles,
                filters: BASE_DEFAULT_STATE.filters,
                // TODO too much hardcoded stuff
                selectedFilters: {
                    "-106": "",
                    "-104": "",
                    "-102": "",
                    "-103": "",
                    "9184": "",
                    "-100": "",
                    "2217": "",
                    "8976": "",
                    "73": "",
                    "74": "",
                    "-111": "",
                    "-112": "",
                    "8977": ""
                },
            }
        }
        case "APPLY_BUTTON_LOADING": {
            return {
                ...state,
                applyBtnLoading: action.payload
            }
        }
        case "SAVE_SELECTED_CAR_PARK": {
            return {
                ...state,
                selectedCarParkId: action.payload,
            }
        }
        case "SAVE_SELECTED_RIM_SIZE": {
            return {
                ...state,
                selectedRimSize: action.payload
            }
        }
        case "UPDATE_SELECTED_FILTERS": {
            const selectedFilters = action.payload
            const { filters } = state
            const additionalManufacturers = filters.manufacturers.filter(filter => !filter.hasTradeReference)
            const isAdditionalManufacturerSelected = selectedFilters[FilterCriterias.rimSupplier.toString()]?.split(";").some(selectedFilter => additionalManufacturers.find(additionalManufacturer => additionalManufacturer.attributeValue == selectedFilter))

            return {
                ...state,
                selectedFilters: action.payload,
                isAdditionalManufacturerSelected
            }
        }
        case "RIM_ERP_INFORMATION_LOADING": {
            const data = state.articles.data.map(x => {
                const rimErpInfo = x.rimErpInformations.map(info => {
                    const isValidForErpRequest = !!action.payload.find(erp => erp.itemId == info.itemId)
                    return {
                        ...info,
                        ...isValidForErpRequest && {
                            loading: true
                        }
                    }
                })
                return {
                    ...x,
                    rimErpInformations: rimErpInfo
                }
            })
            return {
                ...state,
                articles: {
                    ...state.articles,
                    data: [...data],
                    ...state.articles.hasMoreItems ? { loadingNextItems: true } : { loading: true }
                },
                initialized: false
            }
        }
        case "RIM_ERP_INFORMATION_ERROR": {
            const data = state.articles.data.map(x => {
                const rimErpInfo = x.rimErpInformations.map(info => {
                    return {
                        ...info,
                        loading: false
                    }
                })
                return {
                    ...x,
                    rimErpInformations: rimErpInfo
                }
            })
            return {
                ...state,
                articles: {
                    ...state.articles,
                    data: [...data],
                    loadingNextItems: false,
                    loading: false
                },
                initialized: true
            }
        }
        case "SET_ERP_INFORMATIONS": {
            const rims = state.articles.data.map(x => {
                const rimErpInfo = x.rimErpInformations.map(info => {
                    const erpInfo = action.payload.find(erp => erp.itemId == info.itemId)
                    return {
                        ...info,
                        ...erpInfo?.prices && { prices: erpInfo?.prices },
                        ...erpInfo?.availability && {
                            availability: erpInfo?.availability,
                            loading: false
                        },
                        ...erpInfo?.warehouses && { warehouses: erpInfo?.warehouses }
                    }
                })
                return {
                    ...x,
                    rimErpInformations: rimErpInfo
                }
            })

            const { selectedRimItem } = state
            const prevSelectedRim = rims.find(x => selectedRimItem && (x.idRimDesign == selectedRimItem.idRimDesign && x.sizeInfo?.some(y => y.size == state.selectedSize)))
            const shouldSelectFirstRim = !prevSelectedRim

            return {
                ...state,
                articles: {
                    ...state.articles,
                    data: [...rims],
                    ...state.articles.hasMoreItems ? { loadingNextItems: false } : { loading: false }
                },
                initialized: true,
                ...shouldSelectFirstRim && rims?.[0] &&
                { selectedRimSize: rims[0].rimErpInformations?.filter(info => info.diameterSize == rims[0].sizeInfo?.last()?.size.toString())?.first() },
                ...prevSelectedRim && {
                    selectedRimSize: prevSelectedRim?.rimErpInformations?.filter(info => info.diameterSize == selectedRimItem?.sizeInfo?.last()?.size.toString())?.first()
                }
            }
        }

        case "CHANGE_AVAILABILITY_FILTER": {
            return {
                ...state,
                availabilityFilter: action.payload
            }
        }
    }
    return state
}

function loadRimFilterAndArticles(loadFilters: boolean = true): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const { selectedRegistrationNo } = getState().vehicleSelection
        const { selectedFilters, selectedCarParkId } = getState().wheelsList.base

        batch(() => {
            loadFilters && dispatch({ type: "RIM_FILTERS_LOADING" })
            dispatch({ type: "RIM_ARTICLES_LOADING" })
        })

        if (selectedRegistrationNo) {
            Repositories.loadRimArticleFilter(selectedRegistrationNo, mapSelectedFiltersForRequest(selectedFilters), selectedCarParkId).then(
                response => {
                    batch(() => {
                        dispatch({ type: "RIM_FILTERS_LOADED", payload: { rimFilters: response.filters, selectedFilters: undefined } })
                        dispatch({ type: "RIM_ARTICLES_LOADED", payload: { rims: response.rimArticles, hasMoreItems: false } })
                    })
                },
                (e: any) => {
                    console.debug(e)
                    dispatch({ type: "RIM_ARTICLES_ERROR" })
                }
            )
        }
        else {
            batch(() => {
                loadFilters && dispatch(loadRimFilters())
                dispatch(loadRimArticles())
            })
        }
    }
}

const mapArticlesToRequest = (rimItems: RimItem[]) => {
    const articles: RimErpInfo[] = rimItems.flatMap(article => {
        const rimErpInformations = article.rimErpInformations.filter(info => info?.dataSupplierArticleNumber?.length > 0 && info?.wholesalerArticleNumber?.length > 0)
        const infos: RimErpInfo[] = rimErpInformations.map(info => {
            return {
                itemId: info.itemId,
                description: info.description,
                diameterSize: info.diameterSize,
                stock: info.stock,
                ean: info.ean,
                dataSupplierArticleNumber: info.dataSupplierArticleNumber,
                wholesalerArticleNumber: info.wholesalerArticleNumber,
                prices: info.prices,
                availability: info.availability,
                loading: info.loading,
                warehouses: info.warehouses,
                rimManufacturer: info.rimManufacturer,
                rimDescription: info.rimDescription,
                supplierId: article.supplierId,
                supplierName: article.suplierName,
                productGroupId: info.productGroupId
            }
        })
        return infos
    })
    return articles.filter(article => article.availability == undefined && article.prices == undefined)
}

export async function getErpInfoHistory(articles: RimItem[], telesalesCustomerNo?: string, erpSystemConfigId?: number) {
    const Erp: ErpContainer = Container.getInstance(RegisteredModels.ERP)
    const rimItems = mapArticlesToRequest(articles)

    if (!rimItems.length) return

    const rimsErpInformations = await Promise.all(
        rimItems.map(item =>
            Erp.action("getErpInfo")({
                item: createErpInformationRequestItem(item!, getBundleParams().showIndustrialAvailability),
                distributorId: erpSystemConfigId,
                telesalesCustomerNo,
                maxItemsPerRequest: 20
            })
        )
    )
    const rims: RimItem[] = articles.map(x => {
        const rimErpInfo = x.rimErpInformations.map(info => {
            const erpInfo = rimsErpInformations?.find(erp => erp.itemId == info.itemId)
            return {
                ...info,
                ...erpInfo?.prices && { prices: erpInfo?.prices },
                ...erpInfo?.availability && {
                    availability: erpInfo?.availability,
                    loading: false
                },
                ...erpInfo?.warehouses && { warehouses: erpInfo?.warehouses }
            }
        })
        return {
            ...x,
            rimErpInformations: rimErpInfo
        }
    })
    return rims
}

export function getErpInfos(telesalesCustomerNo?: string, erpSystemConfigId?: number): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const Erp: ErpContainer = Container.getInstance(RegisteredModels.ERP)
        const articles = mapArticlesToRequest(getState().wheelsList.base.articles.data)

        if (!articles.length) return

        dispatch({ type: "RIM_ERP_INFORMATION_LOADING", payload: articles })

        Promise.all(
            articles.map(item =>
                Erp.action("getErpInfo")({
                    item: createErpInformationRequestItem(item!, getBundleParams().showIndustrialAvailability),
                    distributorId: erpSystemConfigId,
                    telesalesCustomerNo,
                    maxItemsPerRequest: 20
                })
            )
        ).then(
            responses => {
                dispatch({ type: "SET_ERP_INFORMATIONS", payload: responses })
            },
            () => {
                dispatch({ type: "RIM_ERP_INFORMATION_ERROR" })
            }
        )
    }
}

function loadRimFilters(): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const { showAdditionalManufacturers } = getBundleParams()
        const { carPark: { carparkId }, isAdditionalManufacturerSelected } = getState().wheelsList.base
        const { vehicle } = getState().manager

        if (!carparkId || !vehicle) return
        const filtersRequest = createRimFiltersRequest(carparkId, showAdditionalManufacturers ?? isAdditionalManufacturerSelected)

        if (getBundleParams().vehicleRecordsEnabled) {
            Promise.all([Repositories.loadRimFilters(filtersRequest)]).then(
                ([rimFilters]) => {
                    dispatch({ type: "RIM_FILTERS_LOADED", payload: { rimFilters, selectedFilters: undefined } })
                }
            )
        }
        else {
            Repositories.loadRimFilters(filtersRequest).then(
                rimFilters => dispatch({ type: "RIM_FILTERS_LOADED", payload: { rimFilters, selectedFilters: BASE_DEFAULT_STATE.selectedFilters } })
            )
        }
    }
}

function loadRimArticles(): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const { selectedFilters, carPark: { carparkId }, isAdditionalManufacturerSelected } = getState().wheelsList.base
        const request = createRimArticlesRequest(carparkId, selectedFilters, isAdditionalManufacturerSelected)
        Repositories.loadRimItems(request).then(
            response => {
                batch(() => {
                    dispatch({ type: "RIM_ARTICLES_LOADED", payload: { rims: response, hasMoreItems: true } })
                })
            },
            () => dispatch({ type: "RIM_ARTICLES_ERROR" })
        )
    }
}

export function loadNextRimArticles(showAvailability: boolean, telesalesCustomerNo?: string): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const { articles: { loadingNextItems, loading } } = getState().wheelsList.base
        const request = createNextRimArticlesRequest(getState().wheelsList)
        if (!request || loadingNextItems || loading) return

        dispatch({ type: "RIM_NEXT_ARTICLES_LOADING" })
        Repositories.loadRimItems(request).then(
            response => {
                batch(() => {
                    dispatch({ type: "RIM_NEXT_ARTICLES_LOADED", payload: response })
                    showAvailability && dispatch(getErpInfos(telesalesCustomerNo))
                })
            },
            () => dispatch({ type: "RIM_NEXT_ARTICLES_ERROR" })
        )
    }
}

export function loadVehicleInformations(): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        dispatch({ type: "ADDITIONAL_VEH_INFOS_LOADING" })
        Repositories.loadAdditionalVehicleInfos(getState().wheelsList.base.carPark.carparkId).then(
            response => dispatch({ type: "ADDITIONAL_VEH_INFOS_LOADED", payload: response }),
            () => dispatch({ type: "ADDITIONAL_VEH_INFOS_ERROR" })
        )
    }
}

export function loadDriveRightTyres(): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const { vehicle } = getState().manager
        if (!vehicle) return

        dispatch({ type: "DRIVE_RIGHTS_TYRES_LOADING" })
        Repositories.loadDriveRightTires(vehicle.tecDocTypeId).then(
            response => dispatch({ type: "DRIVE_RIGHTS_TYRES_LOADED", payload: response }),
            () => dispatch({ type: "DRIVE_RIGHTS_TYRES_ERROR" })
        )
    }
}

export function load2DRimData(): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const { selectedRimItem, carPark: { carparkId }, selectedSize } = getState().wheelsList.base
        const { _2D: { rimSizes, images: { isNewImage }, rimPositions } } = getState().wheelsList.configurator

        if (!selectedRimItem || !selectedSize || !selectedRimItem.frontPicture)
            return

        dispatch({ type: "SELECTED_RIM_ARTICLE_LOADING" })

        if (rimSizes[selectedSize] && !rimPositions?.defaultRimSize) {
            dispatch({ type: "SELECTED_RIM_ARTICLE_LOADED", payload: { sizes: rimSizes[selectedSize], selectedSize } })
        }
        else {
            const request = createRimSizesRequest(carparkId, isNewImage, selectedSize)
            const defaultRimSize = rimPositions?.defaultRimSize?.toString() || ""
            const defaultRequest = defaultRimSize && defaultRimSize != selectedSize && createRimSizesRequest(carparkId, isNewImage, defaultRimSize)

            Promise.all(
                [
                    Repositories.load2DRimSizes(request),
                    defaultRequest && Repositories.load2DRimSizes(defaultRequest)
                ]).then(([sizes, defaultSizes]) => {
                    if (defaultSizes && defaultSizes.length > 0 && defaultRimSize) {
                        return dispatch({ type: "SELECTED_RIM_ARTICLE_LOADED", payload: { sizes, selectedSize, defaultSizes, defaultRimSize } })
                    }
                    return dispatch({ type: "SELECTED_RIM_ARTICLE_LOADED", payload: { sizes, selectedSize } })
                },
                    () => dispatch({ type: "SELECTED_RIM_ARTICLE_ERROR" }))
        }
    }
}

function saveWheelLisTab(selectedRim: RimItem, selectedSize: string): AsyncAction<BundleActionTypes, MainState> {
    return (dispatch, getState) => {

        const { pageIndex } = getState().wheelsList.base.articles
        const { selectedFilters, isAdditionalManufacturerSelected } = getState().wheelsList.base
        const { selectedRegistrationNo } = getState().vehicleSelection

        dispatch(BundleActions.saveData({
            ...(selectedRegistrationNo && { registrationNumber: selectedRegistrationNo }),
            rimListTab: {
                article: {
                    idRimDesign: selectedRim.idRimDesign!, supplierId: selectedRim.supplierId!
                },
                selectedSize: selectedSize!,
                pageIndex,
                selectedFilters,
                isAdditionalManufacturerSelected
            },
            activeStep: WheelSelectionSteps.WHEELDETAILS,
            highestStepReached: WheelSelectionSteps.WHEELDETAILS
        }))
    }
}

function changeRimInfoAndSettingVisibility(showRimInfo: boolean, showRimSettings: boolean): ComponentActionType {
    return { type: "CHANGE_RIM_INFO_AND_SETTINGS_VISIBILITY", payload: { rimInfoVisibility: showRimInfo, rimSettingsVIsibility: showRimSettings } }
}

function updateSelectedFilters(selectedFilters: SelectedFilters): ComponentActionType {
    return { type: "UPDATE_SELECTED_FILTERS", payload: selectedFilters }
}

function changeApplyBtnLoading(value: boolean): ComponentActionType {
    return { type: "APPLY_BUTTON_LOADING", payload: value }
}

function sendRimArticleToDetails(rimItem: RimItem, selectedFilters: SelectedFilters, size: string, configuratorImage?: string): ComponentActionType {
    return { type: "SEND_RIM_ARTICLE_TO_DETAILS", payload: { rimItem, selectedFilters, configuratorImage, size } }
}

function selectRimItem(rimItem: RimItem, size: string): ComponentActionType {
    return { type: "SELECTED_RIM_ARTICLE_UPDATE", payload: { rimItem, size } }
}

function saveLatestCarPark(carParkId: number): ComponentActionType {
    return { type: "SAVE_SELECTED_CAR_PARK", payload: carParkId }
}

function saveSelectedRimSize(selectedRimSize: RimErpInfo): ComponentActionType {
    return { type: "SAVE_SELECTED_RIM_SIZE", payload: selectedRimSize }
}

function changeAvailabilityFilter(value: AvailabilityFilterType): ComponentActionType {
    return { type: "CHANGE_AVAILABILITY_FILTER", payload: value }
}

export type IBaseActions = typeof BaseActions

export const BaseActions = {
    loadRimArticles,
    loadNextRimArticles,
    updateSelectedFilters,
    load2DRimData,
    changeRimInfoAndSettingVisibility,
    changeApplyBtnLoading,
    sendRimArticleToDetails,
    selectRimItem,
    loadRimFilters,
    loadDriveRightTyres,
    loadVehicleInformations,
    saveLatestCarPark,
    saveWheelLisTab,
    loadRimFilterAndArticles,
    getErpInfos,
    saveSelectedRimSize,
    changeAvailabilityFilter
}