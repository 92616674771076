import { useLocalization } from "@tm/localization"
import { useCallback, useState } from "react"

export function usePdfDownload() {
    const { translateText } = useLocalization()
    const [isError, setIsError] = useState(false)

    const downloadPdf = useCallback(async (pdfUrl: string) => {
        try {
            const response = await fetch(pdfUrl)
            if (response.ok) {
                const blob = await response.blob()
                const completeFileName = translateText(13811)
                const blobUrl = window.URL.createObjectURL(new Blob([blob], { type: "application/pdf" }))

                const a = document.createElement("a")
                a.href = blobUrl
                a.download = completeFileName
                a.click()

                window.URL.revokeObjectURL(blobUrl)
            } else {
                setIsError(true)
            }
        } catch (error) {
            setIsError(true)
        }
    }, [])

    return { downloadPdf, isError }
}
