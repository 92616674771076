export const checkEmail = (email: string) => {
    const reg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return reg.test(String(email))
}

export const isEmailValid = (email: string) => {
    if (email.length === 0) {
        return false
    }

    if (checkEmail(email)) {
        return true
    }

    return false
}
