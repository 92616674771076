import { Article, WatchListItem } from "@tm/models"
import { useCallback, useMemo } from "react"
import { useUser } from "@tm/context-distribution"
import { mapArticleToCompilationArticle } from "../../../../../../compilations/src/data/mapper"
import {
    useCompilationsContains,
    useCompilations,
    useCreateCompilation,
    useAddArticle,
    useDeleteArticles,
} from "../../../../../../compilations/src/data/hooks/useCompilations"
import { isArticleInWatchlist, sortByCreatedAtDesc } from "../../../../utils/watchlist"

export function useWatchlist(article: Article) {
    const mappedArticles = mapArticleToCompilationArticle({
        articles: article ? [article] : undefined,
        wholesaler: undefined,
    })

    const { containedArticles, loading: containedArticlesLoading } = useCompilationsContains(mappedArticles)
    const { compilations, isLoading: compilationsLoading } = useCompilations()
    const { createCompilation, creatingCompilation: isCreating } = useCreateCompilation()
    const { addArticle, isLoading: addArticleLoading } = useAddArticle()
    const { deleteArticles, isLoading: deleteArticleLoading } = useDeleteArticles()
    const { userContext } = useUser()

    const watchLists: WatchListItem[] = useMemo(
        () =>
            compilations?.sort(sortByCreatedAtDesc)?.map((c) => {
                const containedArticle = containedArticles.find((cA) => cA.compilationId === c.id)
                const isSelected = isArticleInWatchlist(article, containedArticle)

                return {
                    id: c.id,
                    name: c.name,
                    selected: isSelected ?? false,
                }
            }) ?? [],
        [compilations, containedArticles, article]
    )

    const isInWatchList = useMemo(
        () => containedArticles?.find((containedArticle) => isArticleInWatchlist(article, containedArticle)) !== undefined,
        [containedArticles, article]
    )

    const toggleWatchList = useCallback(
        (listId: string) => {
            const containedArticle = containedArticles?.find((c) => c.compilationId === listId)
            const existingInList = isArticleInWatchlist(article, containedArticle) ? containedArticle?.id : undefined

            if (existingInList) {
                deleteArticles({ compilationId: listId, articleIds: [existingInList] })
            } else {
                addArticle({ compilationId: listId, articles: mappedArticles })
            }
        },
        [article, containedArticles, mappedArticles]
    )

    const createOrAddWatchlist = useCallback(
        (name: string) => {
            const wl = watchLists.find((wl) => wl.name === name)
            if (!wl) {
                createCompilation({ name, description: "", isPublic: true }).then((listId) => {
                    toggleWatchList(listId)
                })
            } else {
                toggleWatchList(wl.id)
            }
        },
        [watchLists]
    )

    const createWatchList = useCallback((name: string) => {
        createCompilation({ name, description: "", isPublic: true })
    }, [])

    const isLoading = containedArticlesLoading || compilationsLoading
    const isToggling = addArticleLoading || deleteArticleLoading

    return {
        watchLists,
        isInWatchList,
        createWatchList,
        toggleWatchList,
        createOrAddWatchlist,
        isLoading,
        isToggling,
        isCreating,
        singleWatchlist: userContext.isAnonym,
    }
}
