import { Box, Button, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ArticleListSortingMode } from "@tm/models"
import { Fragment, useCallback } from "react"
import { ArticleGroup } from "../components/ArticleGroup"
import { NextPageLoader } from "../components/NextPageLoader"
import { NoSearchResult } from "../components/NoSearchResult"
import { ArticleSelection } from "../components/ArticleSelection"
import { useArticleSelection } from "../hooks/useArticleSelection"
import { useDirectSearchModuleState } from "./DirectSearchModuleState"
import { OeInformation } from "../components/OeInformation"
import { OeArticle } from "../components/OeArticle"

export function DirectSearchList() {
    const {
        isLoading,
        isFetchingNextPage,
        isLoaded,
        isFailed,
        articleGroups,
        exactMatchArticleGroups,
        loadNextPage,
        hasNextPage,
        isStalling,
        isFrontendFiltered,
    } = useDirectSearchModuleState((x) => x.articles)
    const erpInfosData = useDirectSearchModuleState((x) => x.erpInfosData)
    const oeInformation = useDirectSearchModuleState((x) => x.oeInformation)
    const oensArticles = useDirectSearchModuleState((x) => x.oensArticles)
    const oeReplacement = useDirectSearchModuleState((x) => x.oeReplacement)
    const options = useDirectSearchModuleState((x) => x.options)
    const query = useDirectSearchModuleState((x) => x.params.startParams.query)
    const oeManufacturerIds = useDirectSearchModuleState((x) => x.params.startParams.oeManufacturerIds)
    const { productGroups, setForProductGroups } = useDirectSearchModuleState((x) => x.params.sorting)

    const { translateText } = useLocalization()

    const handleChangeSorting = useCallback(
        (productGroupId: number, value: ArticleListSortingMode | undefined) => {
            setForProductGroups([productGroupId], value)
        },
        [setForProductGroups]
    )

    const { selectedArticles } = useArticleSelection()
    const distance = selectedArticles.length > 0 ? "-50px" : ""

    const showPageLoader = ((hasNextPage || isLoading) && !isFrontendFiltered) || isStalling

    // needs to be refactord - always display OE Articles if exist!
    if (
        (!isFetchingNextPage &&
            isLoaded &&
            !isLoading &&
            !exactMatchArticleGroups.length &&
            !articleGroups.length &&
            !oensArticles.oeParts?.length) ||
        (isFailed && !showPageLoader && !oensArticles.oeParts?.length)
    ) {
        return (
            <>
                <OeInformation
                    oeInformation={oeInformation.oeInformation}
                    isLoading={oeInformation.isLoading}
                    query={query}
                    oeManufacturerIds={oeManufacturerIds}
                />
                <NoSearchResult searchType="direct" />
            </>
        )
    }

    return (
        <Box position="relative" top={distance}>
            <ArticleSelection
                maxArticleNumberToCompareReached
                showArticleComparision
                erpInfosData={erpInfosData}
                hideBasketButton={options.hideAddToBasketButton}
            />

            <OeInformation
                oeInformation={oeInformation.oeInformation}
                isLoading={oeInformation.isLoading}
                query={query}
                oeManufacturerIds={oeManufacturerIds}
            />
            <OeArticle oensArticlesData={oensArticles} oeReplacement={oeReplacement} />

            {isLoaded &&
                !isStalling &&
                exactMatchArticleGroups.map((group, index) => (
                    // Disable ESLint rule here, because there can be multiple groups with the same product group id
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={`${group.productGroup.id}-${index}`}>
                        <Typography variant="body1">{translateText(806).replace("%0", `${exactMatchArticleGroups.length}`)} </Typography>
                        <ArticleGroup group={group} currentSorting={productGroups[group.productGroup.id]} onChangeSorting={handleChangeSorting} />
                    </Fragment>
                ))}

            {isLoaded && !isStalling && articleGroups.length > 0 && (
                <>
                    <Typography variant="body1">{exactMatchArticleGroups.length > 0 ? translateText(808) : translateText(13188)}</Typography>
                    {articleGroups.map((group, index) => (
                        <ArticleGroup
                            // Disable ESLint rule here, because there can be multiple groups with the same product group id
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${group.productGroup.id}-${index}`}
                            group={group}
                            currentSorting={productGroups[group.productGroup.id]}
                            onChangeSorting={handleChangeSorting}
                        />
                    ))}
                </>
            )}

            {showPageLoader && (
                <NextPageLoader
                    loadNextPage={loadNextPage}
                    canLoadNextArticles={hasNextPage}
                    skeletonArticleCount={articleGroups.length ? 1 : 5}
                    skeletonCategory
                />
            )}

            {hasNextPage && isFrontendFiltered && (
                <Box display="flex" justifyContent="center">
                    <Button color="highlight" onClick={loadNextPage}>
                        {translateText(12430)}
                    </Button>
                </Box>
            )}
        </Box>
    )
}
