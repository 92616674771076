import {
    ActiveVehicleDataProviders,
    Article,
    ArticleListType,
    channel,
    DefaultArticleItemState,
    ErpInformation,
    OrderVoucherSupplierItem,
    SupplierArticleDto,
    SupplierArticleQuantities,
    ListOptions,
    ConfigParams,
} from "@tm/models"
import { ArticleItem, ArticleItemStateProvider } from "@tm/components"
import { useCallback, useEffect, useMemo, useState } from "react"
import { isEqual } from "lodash"
import { copyToClipboard } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import Morpheus from "@tm/morpheus"
import { useArticleActions } from "../ListV2/hooks/useArticleItem/useArticleActions"
import { useLoadErpInfos } from "../ListV2/hooks/useErpInfos"
import { useDisplayArticle } from "../ListV2/hooks/useArticleItem/useDisplayArticle"
import { useArticleErpInfos } from "../ListV2/hooks/useArticleItem/useArticlesErpInfos"
import { useHandleClickDetails } from "../ListV2/hooks/useArticleItem/useHandleClickDetails"
import { useHandleClickImage } from "../ListV2/hooks/useArticleItem/useHandleClickImage"
import { useHandleClickReference } from "../ListV2/hooks/useHandleClickReference"
import { useHandleAddToBasket } from "../ListV2/hooks/useArticleItem/useHandleAddToBasket"
import { useHandleAddToCostEstimation } from "../ListV2/hooks/useArticleItem/useHandleAddToCostEstimation"
import { useHandleRemoveFromBasket } from "../ListV2/hooks/useArticleItem/useHandleRemoveFromBasket"
import { useArticleImages } from "../ListV2/hooks/useArticleImages"
import { useArticleNotes } from "../ListV2/hooks/useArticleItem/useArticleNotes"
import AvailabilityComponent from "../../../../erp/src/_shared/availability-wrapper"
import { useArticleNotifications } from "../ListV2/hooks/useArticleItem/useArticleNotifications"
import { useWatchlist } from "../ListV2/hooks/useArticleItem/useWatchlist"
import { useArticleExtendedDeliveryInfo } from "../ListV2/hooks/useArticleItem/useArticleExtendedDeliveryInfo"
import { useArticleSelection } from "../ListV2/hooks/useArticleSelection"
import { ErpInfosData, ProductGroupRepairTimesData, SupplierLogosData } from "../ListV2/models"
import { useShowArticleDetails } from "../ListV2/hooks/useShowArticleDetails"
import { NotesParams } from "../ListV2/hooks/useNotes"
import { useGetRepairTimesUrl } from "../ListV2/hooks/useGetRepairTimesUrl"
import { ArticleFeedbackConfiguration } from "../ListV2/ArticleListConfiguration/useArticleFeedbackConfiguration"
import { useArticleListConfiguration } from "../ListV2/ArticleListConfiguration"
import { useDmsArticleInfo } from "../../../../dms/src/hooks/useDmsArticleInfo"

type PureArticleItemProps = {
    article: Article
    basketQuantities: SupplierArticleQuantities[]
    updateBasketQuantities(articles: Article[]): void
    supplierLogosData?: SupplierLogosData
    notesParams: NotesParams
    productGroupRepairTimesData?: ProductGroupRepairTimesData
    feedbackConfiguration: ArticleFeedbackConfiguration
    showNotes: boolean
    showExtendedDelivery: boolean
    options: ListOptions
    activeVehicleDataProviders?: ActiveVehicleDataProviders
    previouslyOrderedArticles?: OrderVoucherSupplierItem[]
    erpInfosData: ErpInfosData
    loadNextRecommendedArticle?: (article: Article) => Promise<void>
}

export function PureArticleItem({
    article,
    basketQuantities,
    updateBasketQuantities,
    notesParams,
    productGroupRepairTimesData,
    feedbackConfiguration,
    showNotes,
    previouslyOrderedArticles,
    showExtendedDelivery,
    options,
    activeVehicleDataProviders,
    erpInfosData,
    loadNextRecommendedArticle,
}: PureArticleItemProps) {
    const listType: ArticleListType = "pure"
    const { viewOptions } = useArticleListConfiguration()
    const { translateText } = useLocalization()

    const [expanded, setExpanded] = useState(!viewOptions.compact)

    useEffect(() => {
        setExpanded(!viewOptions.compact)
    }, [viewOptions.compact])

    const toggleExpand = useCallback(() => {
        setExpanded((prev) => !prev)
    }, [])

    const loadErpInfos = useLoadErpInfos(erpInfosData)
    const displayArticle = useDisplayArticle(article, [], undefined, false)
    const [articleQuantity, setArticleQuantity] = useState(article.quantity)
    const [originalQuantity, setOriginalQuantity] = useState(article.quantity)
    const articleErpInfos = useArticleErpInfos(article, articleQuantity, erpInfosData)
    const defaultArticleErpInfoResponse = articleErpInfos.default?.state === "success" ? articleErpInfos.default.response : undefined
    const handleClickDetails = useHandleClickDetails(article, articleQuantity)
    const { handleClickImage, handleCloseImage, showImageViewer } = useHandleClickImage(article)
    const showArticleDetails = useShowArticleDetails()
    const handleClickReference = useHandleClickReference(article, articleQuantity, showArticleDetails)
    const addToBasket = useHandleAddToBasket(article)

    const removeFromBasket = useHandleRemoveFromBasket()
    const articleImages = useArticleImages(article)
    const notes = useArticleNotes(article, notesParams)

    const alreadyOrdered = useMemo(() => {
        return (
            previouslyOrderedArticles?.some((x) => x.supplierArticleNumber === article.supplierArticleNo && x.supplierId === article.supplier.id) ??
            false
        )
    }, [previouslyOrderedArticles, article.supplierArticleNo, article.supplier.id])

    const repairTimeProviders = productGroupRepairTimesData?.productGroupRepairTimes?.productGroups[article.productGroup.id]
    const addToKva = useHandleAddToCostEstimation(article, addToBasket, activeVehicleDataProviders, options.externalCatalogUrl, repairTimeProviders)

    const { enableAddingButtonsAfterErp } = Morpheus.getParams<ConfigParams>()
    const addButtonsDisabled = !!enableAddingButtonsAfterErp && !erpInfosData.isReady

    const getRepairTimesUrl = useGetRepairTimesUrl()
    const repairTimesUrl = useMemo(() => {
        if (!!repairTimeProviders?.length && repairTimeProviders) {
            return getRepairTimesUrl(article, repairTimeProviders)
        }
    }, [repairTimeProviders, getRepairTimesUrl, article])

    const articleActions = useArticleActions({
        article,
        articleErpInfo: articleErpInfos.default,
        feedbackConfiguration,
        isNotesEnabled: showNotes,
        listType,
        notes: notes.notes,
        repairTimesUrl,
        onClickDetails: handleClickDetails,
        toggleExpand,
        toggleNotes: notes.toggle,
    })
    const notifications = useArticleNotifications(articleErpInfos.default)
    const watchList = useWatchlist(article)
    const selection = useArticleSelection()
    const extendedDeliveryInfo = useArticleExtendedDeliveryInfo(showExtendedDelivery, erpInfosData, articleErpInfos.default)
    const dmsArticleInfo = useDmsArticleInfo(article)

    const basketQuantity = useMemo(
        () =>
            basketQuantities.find((q) =>
                isEqual(q.article, {
                    dataSupplierArticleNumber: article.supplierArticleNo,
                    dataSupplierId: article.supplier.id,
                    productGroupId: article.productGroup.id,
                } as SupplierArticleDto)
            ),
        [basketQuantities, article]
    )

    const postAddToBasket = useCallback(async () => {
        updateBasketQuantities([article])
    }, [article, updateBasketQuantities])

    const handleAddToBasket = useCallback(
        async (quantity?: number, erpInformation?: ErpInformation, erpSystemId?: number) => {
            loadNextRecommendedArticle?.(article)

            await addToBasket(quantity ?? articleQuantity, erpInformation ?? defaultArticleErpInfoResponse, erpSystemId)
            postAddToBasket()
        },
        [article, addToBasket, articleQuantity, defaultArticleErpInfoResponse, loadNextRecommendedArticle, postAddToBasket]
    )

    const handleAddToCostEstimation = useCallback(
        async (quantity?: number, erpInformation?: ErpInformation, erpSystemId?: number) => {
            await addToKva(quantity ?? articleQuantity, erpInformation ?? defaultArticleErpInfoResponse, erpSystemId)
            postAddToBasket()
        },
        [addToKva, articleQuantity, defaultArticleErpInfoResponse, postAddToBasket]
    )

    const handleRemoveFromBasket = useCallback(
        async (itemIds?: string[]) => {
            const ids = itemIds ?? basketQuantity?.articleQuantities?.allPartItemIds
            if (ids?.length) {
                await removeFromBasket(ids)
                updateBasketQuantities([article])
            }
        },
        [basketQuantity, removeFromBasket, updateBasketQuantities, article]
    )

    const handleChangeQuantity = useCallback(
        (value: number, loadErpInfo = true) => {
            setArticleQuantity(value)

            if (loadErpInfo) {
                setOriginalQuantity(value)
                loadErpInfos({ article, quantity: value, erpSystem: articleErpInfos.default?.erpSystem })
                if (articleErpInfos.alternative) {
                    loadErpInfos({ article, quantity: value, erpSystem: articleErpInfos.alternative.erpSystem })
                }
            }
        },
        [article, loadErpInfos, articleErpInfos]
    )

    const handleCopyArticleNumber = useCallback(
        (number: string) => {
            copyToClipboard(number)
            channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(1920) })
        },
        [translateText]
    )

    const articleState = useMemo<DefaultArticleItemState>(
        () => ({
            article,
            articleActions,
            articleErpInfos,
            articleImages,
            displayArticle,
            dmsArticleInfo,
            expanded,
            extendedDeliveryInfo,
            isVehicleDependent: false,
            alreadyOrdered,
            notes,
            notifications,
            options,
            originalQuantity,
            quantity: articleQuantity,
            selection,
            showImageViewer,
            type: "default",
            watchList,
            basketQuantity,
            addToBasketButtonDisabled: addButtonsDisabled,
            addToCostEstimationButtonDisabled: addButtonsDisabled,

            handleAddToBasket,
            handleAddToCostEstimation,
            handleChangeQuantity,
            handleClickDetails,
            handleClickImage,
            handleClickReference,
            handleCloseImage,
            handleRemoveFromBasket,
            handleCopyArticleNumber,
            toggleExpand,

            AvailabilityComponent,
        }),
        [
            article,
            articleActions,
            articleErpInfos,
            articleImages,
            displayArticle,
            dmsArticleInfo,
            expanded,
            extendedDeliveryInfo,
            alreadyOrdered,
            notes,
            notifications,
            options,
            originalQuantity,
            articleQuantity,
            selection,
            showImageViewer,
            watchList,
            basketQuantity,
            addButtonsDisabled,
            handleAddToBasket,
            handleAddToCostEstimation,
            handleChangeQuantity,
            handleClickDetails,
            handleClickImage,
            handleClickReference,
            handleCloseImage,
            handleRemoveFromBasket,
            handleCopyArticleNumber,
            toggleExpand,
        ]
    )

    return (
        <ArticleItemStateProvider value={articleState}>
            <ArticleItem variant="default" />
        </ArticleItemStateProvider>
    )
}
