import { PropsWithChildren } from "react"
import { useLocalization } from "@tm/localization"
import { uniqueId } from "@tm/utils"
import { Box, Icon, Paper, styled, Typography } from "@tm/components"
import { Camera, FileUpload, ShowPicture } from ".."
import { useFastServiceStore } from "../../../data"
import { PrintImage } from "../../../data/models"
import { useWorkImages } from "../../../hooks/useWorkImages"

type Props = {
    path: string
    hasPicture?: (value: boolean) => void
}

export default function Upload({ path, hasPicture }: Props) {
    const { translateText } = useLocalization()
    const selectedStep = useFastServiceStore((state) => state.navigation.active)

    const { images: selectedImagesForPath, savePicture, replacePicture, removePicture } = useWorkImages(selectedStep || "", path)

    const handleResetImage = (id: string) => {
        removePicture(id)
    }

    const handleImageUpload = (newImage: string) => {
        savePicture({ id: uniqueId(), image: newImage })
    }

    const changeImage = (id: string, updatedImage: string) => {
        replacePicture({ id, image: updatedImage })
    }

    const renderPicture = (picture: PrintImage, idx: number) => {
        return <ShowPicture key={idx} imageId={picture.id} image={picture.image} removeImage={handleResetImage} updateImage={changeImage} />
    }

    return (
        <Box display="flex" gap="0.5em" marginTop="1em">
            <UploadPanel icon="camera" headline={translateText(12620)}>
                <Camera disabled={selectedImagesForPath.length >= 10} onImageUpload={handleImageUpload} />
            </UploadPanel>
            <Box display="flex" gap="0.5em" flexWrap="wrap" alignItems="flex-start">
                {selectedImagesForPath?.map(renderPicture)}
                {hasPicture && hasPicture(!!selectedImagesForPath.length)}
                <FileUpload disabled={selectedImagesForPath.length >= 10} onImageUpload={handleImageUpload} />
            </Box>
        </Box>
    )
}

const StyledCameraWrapper = styled(Paper)({
    maxWidth: "35rem",
    minHeight: "25rem",
    maxHeight: "25rem",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    position: "relative",
})

function UploadPanel({ icon, headline, children }: PropsWithChildren<{ icon: string; headline: string }>) {
    return (
        <StyledCameraWrapper>
            <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center" marginBottom="1em" gap="0.5em">
                    <Icon name={icon} />
                    <Typography variant="h3">{headline}</Typography>
                </Box>
                <Box display="flex" flex="1">
                    {children}
                </Box>
            </Box>
        </StyledCameraWrapper>
    )
}
