import { useCallback, useMemo } from "react"
import { useUser } from "@tm/context-distribution"
import { imagesActions, useFastServiceStore } from "../data"
import { PrintImage } from "../data/models"
import { useUploadFile } from "./useUploadFile"
import { dataURLtoFile } from "../helpers"

export function useWorkImages(step: string, workLabel: string) {
    const imageList = useFastServiceStore((state) => state.imagesState.images)
    const images = useMemo(() => imageList[step || ""]?.[workLabel] || [], [step, workLabel, imageList])
    const { uploadFile, isLoading } = useUploadFile()
    const { userContext } = useUser()

    const savePicture = useCallback(
        async (img: PrintImage) => {
            const { stateVehicle } = useFastServiceStore.getState().maintenancePlan

            const fileName = `${"FastService"}_${step}_${stateVehicle?.vin}`
            const imageBuffer = dataURLtoFile(img.image, `${fileName}.jpeg`)

            if (imageBuffer) {
                const uploadedFile = await uploadFile({
                    file: imageBuffer,
                    category: "maintenanceprotocol",
                    stepName: step,
                    vehicleId: stateVehicle?.id,
                    vin: stateVehicle?.vin,
                    mileage: stateVehicle?.mileAge?.toString(),
                    workshopUser: userContext.account?.username ?? "",
                })

                if (uploadedFile) {
                    const newImg = {
                        ...img,
                        id: uploadedFile?.parts?.[0]?.files?.[0]?.id ?? "",
                        image: uploadedFile?.parts?.[0]?.files?.[0]?.meta?.url ?? "",
                    }

                    imagesActions.addImage(step, workLabel, newImg)
                }
            }
        },
        [step, workLabel]
    )

    const replacePicture = useCallback(
        (img: PrintImage) => {
            imagesActions.replaceImage(step, workLabel, img)
        },
        [step, workLabel]
    )

    const removePicture = useCallback(
        (id: string) => {
            imagesActions.deleteImage(step, workLabel, id)
        },
        [step, workLabel]
    )

    return {
        images,
        savePicture,
        replacePicture,
        removePicture,
        isLoading,
    }
}
